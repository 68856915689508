import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  questions: [],
  id: '',
};

const enterpriseSlice = createSlice({
  name: 'enterprise',
  initialState,

  reducers: {
    addToQuestions: (state, action) => ({
      ...state,
      questions: action.payload,
    }),

    addId: (state, action) => ({
      ...state,
      id: action.payload,
    }),

    clearQuestions: (state) => ({
      ...state,
      questions: [],
    }),
  },
});

export const { addToQuestions, addId, clearQuestions } = enterpriseSlice.actions;
export default enterpriseSlice.reducer;
