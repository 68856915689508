import { LinearProgress, Typography } from '@mui/material';
import trainAi from '../../assets/trainAi.png';
import { IllustrationWrapper, Wrapper } from './style';

const TrainingAI = () => (
  <Wrapper>
    <Typography variant="h5" color="white">
      Training AI...
    </Typography>
    <Typography variant="subtitle2" color="text.primary" sx={{ fontSize: '12px' }}>
      Updating the data
    </Typography>
    <IllustrationWrapper src={trainAi} />
    <LinearProgress
      sx={{
        margin: '2rem',
        color: 'primary.main',
        backgroundColor: 'secondary.progressBg',
        width: '100%',
      }}
    />
  </Wrapper>
);

export default TrainingAI;
