/* eslint-disable no-unused-vars */
import styled from '@emotion/styled';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom/dist';
import { SHA1 } from 'crypto-js';
import hero from '../../assets/Group 1000001775.png';
import logo from '../../assets/logo.png';
import { showToast } from '../../utils/showToast';

const LoginWrapper = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

const ContentWrapper = styled.div`
  color: white;
  height: 100vh;
  display: grid;
  place-items: center;
`;
const TextWrapper = styled.div`
  padding: 2.5rem;
  height: 100%;

  @media (max-width: 475px) {
    padding: 1.25rem;
  }
`;

const ImageWrapper = styled.img`
  width: 300px;
  height: 300px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Signup = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [businessName, setBusinessName] = useState('');
  const [botName, setBotName] = useState('admin');
  const [botType, setBotType] = useState('PRIVATE');
  const [botDesc, setBotDesc] = useState('admin');
  const [botDomain, setBotDomain] = useState('admin');

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const headers = {
      'Content-Type': 'application/json',
    };

    try {
      const formData = {
        business_name: businessName,
        bot_name: botName,
        bot_domain: botDomain,
        bot_description: botDesc,
        bot_type: botType,
        admin_name: name,
        admin_email: email,
      };

      const json = JSON.stringify(formData);

      const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/v1/enterprise/onboarding`, json, {
        headers,
      });

      if (response.status === 200) {
        const token = response.data.data.url.split('=')[1];

        const hashPwd = SHA1(password).toString();
        const obj = {
          password: hashPwd,
        };

        const jsonHashPwd = JSON.stringify(obj);

        const res = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/v1/resetPassword`, jsonHashPwd, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.status === 200) {
          showToast('Sign up successful', 'success');
          navigate('/login');
        }
      }
    } catch (error) {
      showToast('Something went wrong!', 'error');
      console.error('Error:', error);
    }
  };

  return (
    <LoginWrapper>
      <ContentWrapper>
        <Box
          sx={{ width: { xs: '350px', sm: '500px', md: '800px' }, bgcolor: 'secondary.light', borderRadius: '16px' }}
        >
          <TextWrapper>
            <img
              src={logo}
              alt="Logo"
              style={{ marginBottom: '2rem', height: 70, display: 'flex', alignItems: 'flex-start' }}
            />
            <Typography
              letterSpacing="1px"
              textAlign="left"
              variant="h4"
              fontWeight="600"
              mb=".5rem"
              sx={{ fontSize: { xs: '20px', sm: '35px' } }}
            >
              Enterprise Sign Up
            </Typography>
            <Stack direction="row" alignItems="center" gap="2rem" justifyContent="space-around">
              <form onSubmit={handleSubmit}>
                <TextField
                  id="business_name"
                  value={businessName}
                  label="Enterprise Name"
                  variant="filled"
                  fullWidth
                  sx={{ marginY: '.5rem' }}
                  onChange={(e) => setBusinessName(e.target.value)}
                />
                <TextField
                  id="name"
                  value={name}
                  label="Name"
                  variant="filled"
                  fullWidth
                  sx={{ marginY: '.5rem' }}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  id="email"
                  value={email}
                  type="email"
                  label="Email"
                  variant="filled"
                  fullWidth
                  sx={{ marginY: '.5rem' }}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  type="password"
                  value={password}
                  id="password"
                  label="Password"
                  variant="filled"
                  fullWidth
                  sx={{ marginY: '.5rem' }}
                  onChange={(e) => setPassword(e.target.value)}
                />

                <Stack>
                  <Button variant="contained" onClick={handleSubmit}>
                    Sign Up
                  </Button>
                  <Typography variant="caption" textAlign="left" sx={{ color: 'white', fontWeight: 500, mt: '.75rem' }}>
                    Already have an account?{' '}
                    <Typography
                      variant="caption"
                      component="span"
                      sx={{ textDecoration: 'underline', color: 'text.grey100', cursor: 'pointer' }}
                      onClick={() => navigate('/login')}
                    >
                      Sign in
                    </Typography>
                  </Typography>
                </Stack>
              </form>
              <div>
                <ImageWrapper src={hero} alt="" />
              </div>
            </Stack>
          </TextWrapper>
        </Box>
      </ContentWrapper>
    </LoginWrapper>
  );
};

export default Signup;
