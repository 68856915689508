import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Avatar, useMediaQuery } from '@mui/material';
import styled from '@emotion/styled';
import logo from '../assets/mainLogo.png';
import { expertLogOut } from '../redux/authSlice';
import { showToast } from '../utils/showToast';

const pages = ['Expert Dashboard', 'Leads', 'Performance'];

const ImgWrapper = styled.img`
  height: 40px;
  cursor: pointer;

  @media (max-width: 475px) {
    max-height: 30px;
  }
`;

const ExpertNavbar = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const location = useLocation();
  const expert = useSelector((state) => state.authSlice.expert);

  // eslint-disable-next-line no-console
  console.log(expert);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElSec, setAnchorElSec] = useState(null);
  const openRight = Boolean(anchorElSec);

  const handleClick = (event) => {
    setAnchorElSec(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElSec(null);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // eslint-disable-next-line no-console

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    dispatch(expertLogOut());
    showToast('Logged out successfully', 'success');
    navigate('/expert-login');
  };

  return (
    <AppBar
      position="static"
      sx={{
        bgcolor: 'secondary.light',
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          {isSmallScreen && <ImgWrapper src={logo} alt="Logo" onClick={() => navigate('/')} />}

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ color: 'white' }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography color="secondary.main" variant="caption" textAlign="center">
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <ImgWrapper src={logo} alt="Logo" onClick={() => navigate('/')} />
          <Box
            sx={{
              flexGrow: 1,
              marginLeft: '1.5rem',
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'flex-start',
            }}
          >
            {pages.map((page) => {
              const route = `/${page.toLowerCase().replace(/\s+/g, '-')}`;

              return (
                <Button
                  disableRipple
                  key={page}
                  component={Link}
                  sx={{
                    my: 2,
                    mr: 2,
                    color: location.pathname === route ? 'white' : 'text.primary',
                    display: 'block',
                    '&:hover': {
                      backgroundColor: 'secondary.light',
                      color: 'primary.main',
                    },
                  }}
                >
                  <Typography
                    color="text.light"
                    variant="caption"
                    textAlign="center"
                    letterSpacing="1px"
                    sx={{
                      textTransform: 'capitalize',
                      fontWeight: location.pathname === route ? 600 : 500,
                      '&:hover': {
                        color: 'primary.main',
                      },
                    }}
                    onClick={() => navigate(`${route}`)}
                  >
                    {page}
                  </Typography>
                </Button>
              );
            })}
          </Box>

          <Box onClick={handleClick} sx={{ flexGrow: 0 }}>
            <Avatar sx={{ bgcolor: 'primary.main', color: 'secondary.main', cursor: 'pointer' }}>
              {expert?.email ? expert.email.charAt(0).toUpperCase() : ''}
            </Avatar>
          </Box>
          <Menu
            anchorEl={anchorElSec}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            open={openRight}
            onClose={handleClose}
            onClick={handleClose}
            sx={{
              mt: '3%',
              '& .MuiPaper-root': {
                backgroundColor: 'secondary.blackShade',
              },
            }}
          >
            <MenuItem sx={{ fontSize: '.9rem' }} onClick={handleLogout}>
              <LogoutRoundedIcon sx={{ mr: '2px' }} />
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ExpertNavbar;
