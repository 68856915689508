/* eslint-disable no-console */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import LoggedNavbar from '../../components/LoggedNavbar';
import { InputWrapper, TableWrapper, CustomDataTable } from '../../components/TrainAI/style';
import { Wrapper } from '../ExpertChatDashboard/style';
import { formatTimestamp, sortChats } from '../../utils/dateUtils';
import ExpertNavbar from '../../components/ExpertNavbar';

export const ActionButton = ({ onClick }) => (
  <Button size="small" variant="contained" onClick={onClick}>
    Reply{' '}
  </Button>
);

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export const DocumentTable = ({ answered, headers }) => {
  const navigate = useNavigate();
  const sortedAnswered = answered && sortChats(answered);

  const handleActionButtonClick = async (id) => {
    console.log(id);
    navigate(`/expert-chat/${id}`);
  };

  const columns = [
    {
      name: 'CLIENT NAME',
      selector: (row) => (
        <>
          <Typography variant="body2" textAlign="left">
            {row.client_name}
          </Typography>
          <Typography variant="caption" textAlign="left">
            {row.company_name}
          </Typography>
        </>
      ),
    },
    {
      name: 'ASSIGNED',
      selector: (row) => row.assigned,
    },
    {
      name: 'INQUIRY',
      selector: (row) => row.inquiry,
      width: '500px',
    },

    {
      name: 'STATUS',
      selector: (row) => (
        <Chip
          color="success"
          size="small"
          label="New"
          sx={{
            borderRadius: '4px',
            fontSize: '10px',
            background: 'rgba(41, 204, 106, 0.2) ',
            color: '#29CC6A',
            padding: '2px 6px',
          }}
        />
      ),
    },
    {
      name: 'Actions',
      cell: (row) => <ActionButton onClick={() => handleActionButtonClick(row.id)} headers={headers} />,
    },
  ];

  const data =
    sortedAnswered &&
    sortedAnswered.map((item) => ({
      id: item.session_id,
      client_name: item.users[1].username,
      company_name: item.users[1].email,
      assigned: formatTimestamp(item.last_message_updated_at),
      inquiry: item.session_name,
      status: 'ACTIVE',
    }));

  console.log(answered);

  return (
    <TableWrapper>
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{ paddingBottom: '1rem', gap: '1rem' }}
        mb=".5rem"
        justifyContent="space-between"
      >
        <Typography variant="subtitle1" fontWeight={600} color="white">
          Clients
        </Typography>
        <InputWrapper>
          <SearchIcon sx={{ color: 'text.primary', fontSize: '1rem' }} />
          <input type="text" placeholder="Search" />
        </InputWrapper>
      </Stack>
      <div style={{ height: '100%', width: '100%' }}>
        <CustomDataTable columns={columns} data={data} />
      </div>
    </TableWrapper>
  );
};

DocumentTable.propTypes = {
  answered: PropTypes.array.isRequired,
  headers: PropTypes.object.isRequired,
};

const ExpertChatList = () => {
  const [answered, setAnswered] = useState();
  const auth = useSelector((state) => state.authSlice.expert);

  const token = auth && auth.data && auth.data.access_token;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const getAllAnsweredChats = async () => {
    const page = 1;
    const res = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/answered-session?page=${page}`, {
      headers,
    });

    console.log(res);

    if (res.status === 200) {
      setAnswered(res.data.data);
    }
  };

  useEffect(() => {
    getAllAnsweredChats();
  }, []);

  return (
    <div>
      <ExpertNavbar />
      <Wrapper>
        <Box
          sx={{
            bgcolor: 'secondary.light',
            borderRadius: '16px',
            width: '90%',
          }}
        >
          <DocumentTable answered={answered} headers={headers} />
        </Box>
      </Wrapper>
    </div>
  );
};

export default ExpertChatList;
