import React from 'react';
import { Button, Container, Stack, Typography, useMediaQuery } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useNavigate } from 'react-router-dom';
import { MdMail } from 'react-icons/md';
import hero from '../../assets/hero.png';
import logo from '../../assets/logo.png';
import ai from '../../assets/ai.png';
import {
  BannerWrapper,
  Card,
  CardGradient,
  CardWrapper,
  FeatureWrapper,
  Footer,
  ImgWrapper,
  PriceCard,
  PriceCardWrapper,
  PriceWrapper,
  TextWrapper,
} from './style';
import features from '../../utils/features';

const LandingPage = () => {
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleClick = () => {
    navigate('/book-demo');
  };

  return (
    <Container>
      <BannerWrapper>
        <TextWrapper>
          <Stack
            spacing={{
              xs: 1,
              sm: 2,
              md: 3,
            }}
          >
            <Typography variant="caption" component="span" sx={{ fontWeight: 500 }}>
              AI powered &bull; Personalized &bull; Streamlined
            </Typography>
            <Typography variant={isSmallScreen ? 'h5' : 'h3'} sx={{ fontWeight: 600 }}>
              Get qualified leads from users of your Generative Ai
            </Typography>
            <Typography variant="subtitle2" sx={{ color: 'text.primary', fontWeight: 400 }}>
              Create smart chatbots that go way beyond scripted answers that can generate ideas, give reports and nudge
              your customers for expert consultations.
            </Typography>
          </Stack>
          <Stack mt={5} alignItems="center">
            <Button variant="contained" onClick={handleClick} startIcon={<MdMail size={28} />}>
              Book Demo
            </Button>
          </Stack>
        </TextWrapper>{' '}
        <ImgWrapper src={hero} alt="" />
      </BannerWrapper>
      <FeatureWrapper>
        <Typography variant="caption" color="primary.main" component="span" sx={{ fontWeight: 500 }}>
          Features
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          What Outlead Do For Your Business?
        </Typography>
        <Typography variant="subtitle1" color="text.primary" sx={{ fontWeight: 400 }}>
          Outlead is a comprehensive solution that offers a range of benefits to empower your business
        </Typography>
        <CardWrapper>
          {features.map((feature) => (
            <Card key={feature.img}>
              <img src={feature.img} alt="" />
              <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
                {feature.title}
              </Typography>
              <Typography variant="caption" color="text.primary" sx={{ fontWeight: 500 }}>
                {feature.subtitle}
              </Typography>
            </Card>
          ))}
        </CardWrapper>
      </FeatureWrapper>
      <PriceWrapper>
        <Typography variant="caption" color="primary.main" component="span" sx={{ fontWeight: 500 }}>
          Pricing Plans
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          Simple, transparent pricing
        </Typography>

        <PriceCardWrapper>
          <CardGradient />
          <PriceCard>
            <div>
              <Typography variant="h3" sx={{ fontWeight: 500 }}>
                $19.99
                <Typography variant="caption" sx={{ fontWeight: 500 }}>
                  {' '}
                  /month
                </Typography>
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 400 }}>
                Basic Plan
              </Typography>
              <Typography variant="caption" color="text.primary" sx={{ fontWeight: 400 }}>
                Billed annually.
              </Typography>
            </div>

            <Stack alignSelf="flex-start" spacing={2}>
              <Stack direction="row" alignItems="center" justifyContent="flex-start">
                <CheckCircleRoundedIcon color="primary" sx={{ mr: '1rem' }} />
                AI-powered lead generation
              </Stack>
              <Stack direction="row" alignItems="center" jjustifyContent="flex-start">
                <CheckCircleRoundedIcon color="primary" sx={{ mr: '1rem' }} />
                Smart chatbots with generative AI
              </Stack>
              <Stack direction="row" alignItems="center" jjustifyContent="flex-start">
                <CheckCircleRoundedIcon color="primary" sx={{ mr: '1rem' }} />
                Automated FAQ responses
              </Stack>
              <Stack direction="row" alignItems="center" jjustifyContent="flex-start">
                <CheckCircleRoundedIcon color="primary" sx={{ mr: '1rem' }} />
                Streamlined customer support
              </Stack>
            </Stack>
            <Button variant="contained" fullWidth>
              Get Started
            </Button>
          </PriceCard>
          <PriceCard>
            <div>
              <Typography variant="h3" sx={{ fontWeight: 500 }}>
                $49.99
                <Typography variant="caption" sx={{ fontWeight: 500 }}>
                  {' '}
                  /month
                </Typography>
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 400 }}>
                Premium plan
              </Typography>
              <Typography variant="caption" color="text.primary" sx={{ fontWeight: 400 }}>
                Billed annually.
              </Typography>
            </div>

            <Stack alignSelf="flex-start" spacing={2}>
              <Stack direction="row" alignItems="center" justifyContent="flex-start">
                <CheckCircleRoundedIcon color="primary" sx={{ mr: '1rem' }} />
                AI-powered lead generation
              </Stack>
              <Stack direction="row" alignItems="center" jjustifyContent="flex-start">
                <CheckCircleRoundedIcon color="primary" sx={{ mr: '1rem' }} />
                Smart chatbots with generative AI
              </Stack>
              <Stack direction="row" alignItems="center" jjustifyContent="flex-start">
                <CheckCircleRoundedIcon color="primary" sx={{ mr: '1rem' }} />
                Advanced analytics
              </Stack>
              <Stack direction="row" alignItems="center" jjustifyContent="flex-start">
                <CheckCircleRoundedIcon color="primary" sx={{ mr: '1rem' }} />
                24/7 priority support
              </Stack>
            </Stack>
            <Button variant="contained" fullWidth>
              Get Started
            </Button>
          </PriceCard>
        </PriceCardWrapper>
      </PriceWrapper>
      <Footer>
        <img src={logo} alt="" style={{ width: '100px' }} />
        <div>
          <p>Home</p>
          <p>Feature</p>
          <p>Pricing</p>
          <p>Data Protection & Privacy</p>
          <p>Terms of use</p>
        </div>
        <div>
          <p>© 2023 Outlead</p>
        </div>
      </Footer>
      <Stack p={2} direction="row" gap={1} alignItems="center" justifyContent="center">
        Powered by <img src={ai} alt="AI Studio" />
      </Stack>
    </Container>
  );
};

export default LandingPage;
