/* eslint-disable no-console */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import ExpertNavbar from '../../components/ExpertNavbar';
import { InputWrapper, TableWrapper, CustomDataTable } from '../../components/TrainAI/style';
import { Wrapper } from './style';
import { formatTimestamp, sortChats } from '../../utils/dateUtils';
import { showToast } from '../../utils/showToast';

export const ActionButton = ({ onClick }) => (
  <Button size="small" variant="contained" onClick={onClick}>
    Yank{' '}
  </Button>
);

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export const DocumentTable = ({ unanswered, headers, handleRefresh, isRefreshing }) => {
  const navigate = useNavigate();

  console.log(unanswered);

  const sortedUnanswered = unanswered && sortChats(unanswered);

  const handleActionButtonClick = async (id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/expert-session?session_id=${id}`,
        {},
        {
          headers,
        },
      );
      if (response.statusText === 'OK') {
        showToast('Assigned to yourself!', 'success');
        navigate('/leads');
      }
    } catch (error) {
      showToast('Something went wrong!', 'error');
    }
  };

  const columns = [
    {
      name: 'CLIENT NAME',
      selector: (row) => (
        <>
          <Typography variant="body2" textAlign="left">
            {row.client_name}
          </Typography>
          <Typography variant="caption" textAlign="left">
            {row.company_name}
          </Typography>
        </>
      ),
    },
    {
      name: 'ASSIGNED',
      selector: (row) => row.assigned,
    },
    {
      name: 'INQUIRY',
      selector: (row) => row.inquiry,
      width: '500px',
    },

    {
      name: 'STATUS',
      selector: (row) => (
        <Chip
          color="success"
          size="small"
          label="New"
          sx={{
            borderRadius: '4px',
            fontSize: '10px',
            background: 'rgba(41, 204, 106, 0.2) ',
            color: '#29CC6A',
            padding: '2px 6px',
          }}
        />
      ),
    },
    {
      name: 'Actions',
      cell: (row) => <ActionButton onClick={() => handleActionButtonClick(row.id)} headers={headers} />,
    },
  ];

  const data =
    sortedUnanswered &&
    sortedUnanswered.map((item) => ({
      id: item.session_id,
      client_name: item.users[1]?.username,
      company_name: item.users[1]?.email,
      assigned: formatTimestamp(item.last_message_updated_at),
      inquiry: item.session_name,
      status: 'ACTIVE',
    }));

  return (
    <TableWrapper>
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{ paddingBottom: '1rem', gap: '1rem' }}
        mb=".5rem"
        justifyContent="space-between"
      >
        <Typography variant="subtitle1" fontWeight={600} color="white">
          Clients
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            size="small"
            sx={{
              fontWeight: 600,
              color: 'secondary.main',
              '& .MuiSvgIcon-root': {
                fontSize: '1.5rem',
                transition: 'transform 0.3s ease-in-out',
                transform: isRefreshing ? 'rotate(360deg)' : 'rotate(0deg)',
              },
            }}
            startIcon={<RefreshRoundedIcon />}
            onClick={handleRefresh}
          >
            {isRefreshing ? 'Refreshing...' : 'Refresh'}
          </Button>
          <InputWrapper>
            <SearchIcon sx={{ color: 'text.primary', fontSize: '1rem' }} />
            <input type="text" placeholder="Search" />
          </InputWrapper>
        </Stack>
      </Stack>
      <div style={{ height: '100%', width: '100%' }}>
        <CustomDataTable columns={columns} data={data} />
      </div>
    </TableWrapper>
  );
};

DocumentTable.propTypes = {
  unanswered: PropTypes.array.isRequired,
  headers: PropTypes.object.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  isRefreshing: PropTypes.bool.isRequired,
};

const ExpertChartDashboard = () => {
  const [unanswered, setUnanswered] = useState();
  const [refreshKey, setRefreshKey] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const auth = useSelector((state) => state.authSlice.expert);

  const token = auth && auth.data && auth.data.access_token;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const getAllUnansweredChats = async () => {
    const page = 1;
    const res = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/unanswered-session?page=${page}`, {
      headers,
    });

    console.log(res);

    if (res.status === 200) {
      setUnanswered(res.data.data);
    }
  };

  const handleRefresh = () => {
    setIsRefreshing(true);
    getAllUnansweredChats();

    setTimeout(() => {
      setRefreshKey((prevKey) => prevKey + 1);
      setIsRefreshing(false);
    }, 1000);
  };

  useEffect(() => {
    getAllUnansweredChats();
  }, []);

  return (
    <div>
      <ExpertNavbar />
      <Wrapper>
        <Box
          sx={{
            bgcolor: 'secondary.light',
            borderRadius: '16px',
            width: '90%',
          }}
        >
          <DocumentTable
            unanswered={unanswered}
            headers={headers}
            handleRefresh={handleRefresh}
            isRefreshing={isRefreshing}
          />
        </Box>
      </Wrapper>
    </div>
  );
};

export default ExpertChartDashboard;
