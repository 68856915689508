import { v4 as uuidv4 } from 'uuid';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  history: [],
  recentChat: [],
  chat: [],
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,

  reducers: {
    addToHistory: (state, action) => ({
      ...state,
      history: action.payload,
    }),

    appendHistory: (state, action) => {
      const { _id, message } = action.payload;
      const chatIndex = state.history.findIndex((chat) => chat._id === _id);

      if (chatIndex !== -1) {
        const updatedChatHistory = state.history.map((chat, index) =>
          index === chatIndex ? { ...chat, message: chat.message + message } : chat,
        );
        return { ...state, history: updatedChatHistory };
      }

      return {
        ...state,
        history: [...state.history, action.payload],
      };
    },
    appendBotHistory: (state, action) => {
      const { message, user } = action.payload;

      if (user[0].user_type === 'BOT') {
        // Check if there is already a bot message in the history
        const botIndex = state.history.findIndex((chat) => chat.user[0].user_type === 'BOT');

        if (botIndex !== -1) {
          // Create a new entry for the bot message
          const newBotEntry = {
            _id: uuidv4(),
            message,
            user,
          };

          // Insert the new bot message after the last bot message
          const updatedChatHistory = [
            ...state.history.slice(0, botIndex + 1),
            newBotEntry,
            ...state.history.slice(botIndex + 1),
          ];

          return {
            ...state,
            history: updatedChatHistory,
          };
        }
      }

      // Append the message to the chat history as usual
      return {
        ...state,
        history: [...state.history, action.payload],
      };
    },

    addToRecentChat: (state, action) => ({
      ...state,
      recentChat: action.payload,
    }),

    clearChat: (state) => ({
      ...state,
      chat: [],
    }),
  },
});

export const { addToHistory, addToRecentChat, clearChat, appendHistory, appendBotHistory } = chatSlice.actions;
export default chatSlice.reducer;
