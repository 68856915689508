/* eslint-disable no-console */

import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SendIcon from '@mui/icons-material/Send';
import Toolbar from '@mui/material/Toolbar';
import { CircularProgress, Stack, useMediaQuery, useTheme } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { storeSession } from '../../redux/authSlice';
import { FormWrapper, InputWrapper } from './style';
import ChatDashboard from '../../components/ChatDashboard/ChatDashboard';
import UserQuery from '../../components/ChatBox/UserQuery';
import BotResponse from '../../components/ChatBox/BotResponse';
import SideDrawer from '../../components/SideDrawer/SideDrawer';
import { addToHistory, addToRecentChat } from '../../redux/chatSlice';
import isObjEmpty from '../../utils/isObjEmpty';
import { clearQuestions } from '../../redux/enterpriseSlice';

const drawerWidth = 300;

const Chat = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [chat, setChat] = useState([]);
  const [enterprise, setEnterprise] = useState([]);

  const session = useSelector((state) => state.authSlice.session);
  const auth = useSelector((state) => state.authSlice.enterprise);
  const clearChat = useSelector((state) => state.chatSlice.chat);
  const enterpriseID = useSelector((state) => state.enterpriseSlice.id);
  const authUser = useSelector((state) => state.authSlice.user);
  const questions = useSelector((state) => state.enterpriseSlice.questions);

  const sessionId = session.id;
  const dispatch = useDispatch();

  let token;

  if (!isObjEmpty(authUser)) {
    token = authUser?.data.access_token;
  } else if (!isObjEmpty(auth)) {
    token = auth?.data.access_token;
  }

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();

  const socketRef = useRef(null);
  const chatBoxRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (query && query.length < 2) {
      return;
    }

    const queryType = query.startsWith('@ask') ? 'bot_input' : 'user_conversation';
    const updatedQuery = query.replace('@ask', '');

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify({ message: updatedQuery, type: queryType }));
    }

    setLoading(false);
  };

  const createSession = async () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/session?enterprise_id=${enterpriseID}`,
        {},
        { headers },
      );

      if (response.statusText === 'OK') {
        const { session_id } = response.data.data;
        dispatch(storeSession(session_id));
        console.log('SESSION', session_id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getEnterpriseList = async () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/all-enterprises`, { headers });

      if (response.statusText === 'OK') {
        const resData = response.data.data?.enterprises;
        setEnterprise((prevState) => [...prevState, ...resData]);
        setChatLoading(false);
      }
    } catch (error) {
      console.error(`Error from getEnterpriseList ${error}`);
    }
  };

  const getSession = async () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const page = 1;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/session?page=${page}&enterprise_id=${enterpriseID}`,
        {
          headers,
        },
      );

      if (response.statusText === 'OK') {
        const { data } = response.data;
        console.log('CALLED', data, sessionId);
        dispatch(addToRecentChat(data));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllChats = async (session_id) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const page = 1;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/chats?session_id=${session_id}&page=${page}`,
        { headers },
      );

      if (response.statusText === 'OK') {
        dispatch(addToHistory(response.data.data.messages));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSession();
  }, [sessionId, enterpriseID]);

  useEffect(() => {
    getEnterpriseList();
    enterpriseID && createSession();

    return () => {
      dispatch(clearQuestions());
    };
  }, []);

  useEffect(() => {
    setChat([]);
  }, [clearChat]);

  useEffect(() => {
    socketRef.current = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_BASE_API_URL}/ws/${sessionId}/${
        isObjEmpty(auth) ? authUser.data.user_id : auth.data.user_id
      }/${enterpriseID}`,
    );

    socketRef.current.onopen = () => {};

    socketRef.current.onmessage = (event) => {
      const message = event.data;

      const msgObj = JSON.parse(message);

      if (msgObj.type === 'start') {
        setLoading(true);
      }

      if (msgObj.type === 'stream') {
        if (msgObj.sender_type === 'BOT') {
          setChat((prevChat) => {
            const updatedChat = [...prevChat];
            const lastChatIndex = updatedChat.length - 1;

            if (lastChatIndex >= 0 && updatedChat[lastChatIndex].isCompleted === false) {
              updatedChat[lastChatIndex] = {
                ...updatedChat[lastChatIndex],
                response: {
                  ...updatedChat[lastChatIndex].response,
                  message: updatedChat[lastChatIndex].response.message + msgObj.message,
                },
              };
            } else {
              const newObj = {
                id: uuidv4(),
                userQuery: '',
                response: {
                  message: msgObj.message,
                },
                isCompleted: false,
              };
              updatedChat.push(newObj);
            }

            return updatedChat;
          });
        }

        if (msgObj.sender_type === 'ENTERPRISE_EXPERT') {
          setChat((prevChat) => {
            const updatedChat = [...prevChat];
            const newObj = {
              id: uuidv4(),
              userQuery: msgObj.message,
              response: {
                message: '',
              },
              isCompleted: false,
            };
            updatedChat.push(newObj);
            return updatedChat;
          });

          setQuery('');
          getSession();
        }

        if (msgObj.sender_type === 'USER') {
          const updatedQuery = query.replace('@ask', '');

          setChat((prevChat) => {
            const updatedChat = [...prevChat];
            const newObj = {
              id: uuidv4(),
              userQuery: updatedQuery,
              response: {
                message: '',
              },
              isCompleted: false,
            };
            updatedChat.push(newObj);
            return updatedChat;
          });

          setQuery('');
          getSession();
        }
        if (msgObj.sender_type === 'ENTERPRISE_ADMIN') {
          const updatedQuery = query.replace('@ask', '');

          setChat((prevChat) => {
            const updatedChat = [...prevChat];
            const newObj = {
              id: uuidv4(),
              userQuery: updatedQuery,
              response: {
                message: '',
              },
              isCompleted: false,
            };
            updatedChat.push(newObj);
            return updatedChat;
          });

          setQuery('');
          getSession();
        }
      }

      if (msgObj.type === 'end') {
        console.log('--- END ---');
        setLoading(false);
        setQuery('');
        setChat((prevChat) => [
          ...prevChat,
          {
            userQuery: '',
            response: {
              message: '',
            },
            isCompleted: true,
          },
        ]);
        getSession();
      }
    };

    socketRef.current.onclose = () => {
      console.log('close');
    };

    return () => {
      if (socketRef.readyState === 1) {
        socketRef.close();
      }
    };
  }, [query, sessionId, enterpriseID]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = <SideDrawer createSession={createSession} getAllChats={getAllChats} enterprise={enterprise} />;

  if (chatLoading) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ width: '100vw', height: '100vh', backgroundColor: 'secondary.main' }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {isSmallScreen && (
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            backgroundColor: 'secondary.light',
            color: 'white',
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="recent-chats">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, border: 'none' },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        ref={chatBoxRef}
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: 'chat.primary',
          minHeight: '100vh',
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          paddingTop: { xs: '3rem', sm: '0' },
          paddingBottom: '5rem',
        }}
      >
        {' '}
        {chat.length > 0 ? (
          chat.map((msg) => {
            if (msg.isCompleted) {
              return null;
            }

            if (msg.userQuery !== '') {
              return (
                <Box key={msg.id} sx={{ color: 'white' }}>
                  <UserQuery userQuery={msg.userQuery} />
                  {msg.response.message && <BotResponse response={msg.response.message} />}
                </Box>
              );
            }

            return null; // Don't render anything if there is no response from the bot
          })
        ) : (
          <ChatDashboard questions={questions} setQuery={setQuery} />
        )}
      </Box>
      <FormWrapper>
        <form onSubmit={(e) => handleSubmit(e)}>
          <InputWrapper>
            <input type="text" value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Write a prompt" />
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack
                bgcolor={loading ? 'transparent' : 'primary.main'}
                color="secondary.main"
                padding="5px"
                sx={{ borderRadius: '5px' }}
              >
                {loading ? (
                  <BeatLoader
                    color={theme.palette.primary.main}
                    loading={loading}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  <SendIcon onClick={(e) => handleSubmit(e)} sx={{ cursor: 'pointer' }} />
                )}
              </Stack>
            </Stack>
          </InputWrapper>
        </form>
      </FormWrapper>
    </Box>
  );
};

export default Chat;
