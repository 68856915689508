/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-unused-vars
import { Toaster, toast } from 'react-hot-toast';

export const showToast = (message, type) => {
  const toastTypes = {
    success: toast.success,
    error: toast.error,
  };
  const notify = toastTypes[type];

  if (notify) {
    notify(message);
  }
};
