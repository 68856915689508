import featureIcon1 from '../assets/Featured icon.png';
import featureIcon2 from '../assets/Featured icon-1.png';
import featureIcon3 from '../assets/Featured icon-2.png';
import featureIcon4 from '../assets/Featured icon-3.png';
import featureIcon5 from '../assets/Featured icon-4.png';

const features = [
  {
    img: featureIcon1,
    title: 'Generate & Qualify Leads',
    subtitle:
      'Save time and effort by automating the lead generation process. Focus on nurturing leads and converting them into customers.',
  },
  {
    img: featureIcon2,
    title: 'Shorten Your Sales Cycle in Minutes',
    subtitle:
      'Drives faster conversions by delivering instant, accurate information, addressing queries, and resolving concerns with AI precision, accelerating your sales cycle.',
  },
  {
    img: featureIcon3,
    title: 'Answer FAQs',
    subtitle:
      'Automating FAQ responses. Relieve your support team, allowing them to focus on complex customer inquiries while Outlead handles common questions.',
  },
  {
    img: featureIcon4,
    title: 'Customer Support',
    subtitle:
      'Enhances customer support with timely, accurate assistance. Offers solutions and guides customers through self-service options for improved satisfaction and loyalty.',
  },
  {
    img: featureIcon5,
    title: 'Consult with Experts',
    subtitle:
      'Experience seamless expert consultations. Connect with industry professionals, enhancing customer experience, fostering trust, and facilitating meaningful interactions.',
  },
];

export default features;
