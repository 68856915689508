import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authSlice from './authSlice';
import chatSlice from './chatSlice';
import enterpriseSlice from './enterpriseSlice';

const rootReducer = combineReducers({
  authSlice,
  chatSlice,
  enterpriseSlice,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['authSlice', 'chatSlice', 'enterpriseSlice'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
