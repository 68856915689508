import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import styled from '@emotion/styled';
import logo from '../assets/mainLogo.png';

const ImgWrapper = styled.img`
  height: 40px;
  cursor: pointer;

  @media (max-width: 475px) {
    max-height: 30px;
  }
`;

const Navbar = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isMidScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [anchorElSec, setAnchorElSec] = useState(null);
  const openRight = Boolean(anchorElSec);

  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorElSec(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        bgcolor: 'secondary.light',
      }}
      elevation={0}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          {isSmallScreen && <ImgWrapper src={logo} alt="Logo" onClick={() => navigate('/')} />}

          <ImgWrapper src={logo} alt="Logo" onClick={() => navigate('/')} />

          <Box sx={{ flexGrow: 0 }}>
            <Button
              size={isMidScreen ? 'small' : 'medium'}
              onClick={() => setAnchorElSec(true)}
              variant="contained"
              endIcon={<ArrowDropDownIcon />}
            >
              Login
            </Button>
          </Box>
          <Menu
            anchorEl={anchorElSec}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            open={openRight}
            onClose={handleClose}
            onClick={handleClose}
            sx={{
              mt: '3%',
              '& .MuiPaper-root': {
                backgroundColor: 'secondary.blackShade',
              },
            }}
          >
            <MenuItem onClick={() => navigate('/login')}>Enterprise Login</MenuItem>
            <MenuItem onClick={() => navigate('/user-login')}>User Login</MenuItem>
            <MenuItem onClick={() => navigate('/expert-login')}>Expert Login</MenuItem>
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;
