import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/mainLogo.png';

const DemoHeader = () => {
  const navigate = useNavigate();
  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: 'secondary.light',
        height: '10vh',
        zIndex: 9999,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <img
              src={logo}
              alt="Logo"
              style={{ marginRight: 2, height: 40, cursor: 'pointer' }}
              onClick={() => navigate('/')}
            />
          </Stack>

          <Box sx={{ flexGrow: 0 }} onClick={() => navigate('/')}>
            <Button variant="outlined" startIcon={<HomeRoundedIcon />} sx={{ textTransform: 'capitalize' }}>
              Home
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default DemoHeader;
