import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Home from '../pages/Home';
import Login from '../pages/Login';
import BookDemo from '../pages/BookDemo';
import Chat from '../pages/Chat/chat';
import TrainAIPage from '../pages/TrainAIPage/TrainAIPage';
import ChatHistory from '../components/ChatHistory';
import Signup from '../pages/Signup/Signup';
import UserSignup from '../pages/UserSignup/UserSignup';
import UserLogin from '../pages/UserLogin/UserLogin';
import ExpertLogin from '../pages/ExpertLogin/ExpertLogin';
import ExpertSignup from '../pages/ExpertSignup/ExpertSignup';
import ExpertChatDashboard from '../pages/ExpertChatDashboard/ExpertChatDashboard';
import ExpertChatList from '../pages/ExpertChatList/ExpertChatList';
import Experts from '../pages/Experts/Experts';
import ExpertChat from '../pages/ExpertChat/ExpertChat';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,

    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/book-demo',
        element: <BookDemo />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/user-login',
        element: <UserLogin />,
      },
      {
        path: '/expert-login',
        element: <ExpertLogin />,
      },
      {
        path: '/signup',
        element: <Signup />,
      },
      {
        path: '/user-signup',
        element: <UserSignup />,
      },
      {
        path: '/expert-signup',
        element: <ExpertSignup />,
      },
      {
        path: '/train-ai',
        element: <TrainAIPage />,
      },
      {
        path: '/experts',
        element: <Experts />,
      },
      {
        path: '/chat',
        element: <Chat />,
      },
      {
        path: '/expert-chat/:id',
        element: <ExpertChat />,
      },
      {
        path: '/chat/:id',
        element: <ChatHistory />,
      },
      {
        path: '/expert-dashboard',
        element: <ExpertChatDashboard />,
      },
      {
        path: '/leads',
        element: <ExpertChatList />,
      },
    ],
  },
]);

export default router;
