/* eslint-disable consistent-return */
/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import LoggedNavbar from '../../components/LoggedNavbar';
import TrainAI from '../../components/TrainAI/TrainAI';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  min-height: 100vh;
`;

const HeroWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

const TrainAIPage = () => {
  const auth = useSelector((state) => state.authSlice.enterprise);
  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(auth).length === 0) {
      return navigate('/');
    }
    // eslint-disable-next-line no-useless-return
    return;
  }, []);

  return (
    <Wrapper>
      <LoggedNavbar />
      <HeroWrapper>
        <TrainAI />
      </HeroWrapper>
    </Wrapper>
  );
};

export default TrainAIPage;
