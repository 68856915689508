import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ExpertCard from '../../components/ExpertCard';
import LoggedNavbar from '../../components/LoggedNavbar';
import { showToast } from '../../utils/showToast';

const ExpertWrapper = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  padding-top: 3rem;
`;

const Experts = () => {
  const [open, setOpen] = useState(false);
  const [experts, setExperts] = useState([]);
  const { register, handleSubmit } = useForm();

  const auth = useSelector((state) => state.authSlice.enterprise);

  const token = auth && auth.data && auth.data.access_token;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const getAllExperts = async () => {
    const res = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/experts`, { headers });
    if (res.status === 200) {
      setExperts(res.data.data.enterprise_experts);
    }
  };

  const onSubmit = async (data) => {
    const formData = JSON.stringify(data);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/v1/onboard-expert`, formData, {
        headers,
      });
      if (response.statusText === 'OK') {
        showToast('Reset password link sent to the mail', 'success');
        setOpen(false);
      }
    } catch (error) {
      showToast('Something went wrong!', 'error');
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInvite = (e) => {
    e.preventDefault();
    handleClickOpen();
  };

  useEffect(() => {
    getAllExperts();
  }, []);

  return (
    <>
      <LoggedNavbar />
      <ExpertWrapper>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="md"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            '& .MuiPaper-root': {
              backgroundColor: 'secondary.light',
            },
          }}
          BackdropProps={{
            sx: {
              background: 'rgba(255, 255, 255, 0.1)',
              backdropFilter: 'blur(5px)',
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <KeyboardBackspaceRoundedIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />

              <Box />
              <Box />
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2} alignItems="center" justifyContent="center">
              <Avatar sx={{ width: 120, height: 120 }} />
              <Stack direction="row" spacing={2}>
                <Button variant="contained" sx={{ textTransform: 'capitalize' }}>
                  Upload
                </Button>
                <Button variant="outlined" sx={{ textTransform: 'capitalize' }}>
                  Reset
                </Button>
              </Stack>
            </Stack>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid mt={2} container justifyContent="space-between" spacing={2}>
                <Grid item md={6}>
                  <TextField
                    id="username"
                    label="Full Name"
                    variant="filled"
                    fullWidth
                    sx={{ marginY: '.5rem' }}
                    {...register('username', { required: true })}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="email"
                    type="email"
                    label="Email"
                    variant="filled"
                    fullWidth
                    sx={{ marginY: '.5rem' }}
                    {...register('email', { required: true })}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="phone"
                    label="Phone"
                    type="tel"
                    variant="filled"
                    fullWidth
                    sx={{ marginY: '.5rem' }}
                    {...register('phone', { required: true })}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="designation"
                    label="Designation"
                    variant="filled"
                    fullWidth
                    sx={{ marginY: '.5rem' }}
                    {...register('designation', { required: true })}
                  />
                </Grid>
              </Grid>
              <TextField
                id="bio"
                label="Bio"
                variant="filled"
                fullWidth
                sx={{ marginY: '.5rem' }}
                {...register('bio', { required: true })}
              />
            </form>
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: '1rem',
            }}
          >
            <input type="file" style={{ display: 'none' }} />

            <Button
              type="submit"
              variant="contained"
              sx={{ textTransform: 'capitalize' }}
              onClick={handleSubmit(onSubmit)}
            >
              Send Invite
            </Button>
          </DialogActions>
        </Dialog>

        <Box
          sx={{
            width: { xs: '90%', sm: '85%' },
            bgcolor: 'secondary.light',
            mx: 'auto',
            p: 2,
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            color: 'white',
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Manage all your experts team</Typography>
            <Button variant="contained" startIcon={<AddBoxSharpIcon />} onClick={handleInvite}>
              Invite
            </Button>
          </Stack>
          <Stack direction="column" gap={1}>
            {experts.length > 0 ? (
              experts.map((expert) => <ExpertCard key={expert._id} expert={expert} />)
            ) : (
              <Box
                height={150}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ borderRadius: '8px', border: (theme) => `1px solid ${theme.palette.text.grey300}` }}
              >
                <Typography variant="body2">No experts found.</Typography>
              </Box>
            )}
          </Stack>
        </Box>
      </ExpertWrapper>
    </>
  );
};

export default Experts;
