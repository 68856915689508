/* eslint-disable no-console */

import { AppBar, Box, CssBaseline, Drawer, IconButton, Stack, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import SendIcon from '@mui/icons-material/Send';
import { BeatLoader } from 'react-spinners';
import { v4 as uuidv4 } from 'uuid';
import UserQuery from './ChatBox/UserQuery';
import BotResponse from './ChatBox/BotResponse';
import SideDrawer from './SideDrawer/SideDrawer';
import { addToHistory, appendHistory } from '../redux/chatSlice';
import { FormWrapper, InputWrapper } from '../pages/Chat/style';
import isObjEmpty from '../utils/isObjEmpty';

const drawerWidth = 300;

const ChatHistory = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [enterprise, setEnterprise] = useState([]);

  const chatHistory = useSelector((state) => state.chatSlice.history);
  const auth = useSelector((state) => state.authSlice.enterprise);
  const authUser = useSelector((state) => state.authSlice.user);
  const enterpriseID = useSelector((state) => state.enterpriseSlice.id);

  const socketRef = useRef(null);
  const botIDref = useRef(null);

  const dispatch = useDispatch();
  const { id } = useParams();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery((myTheme) => myTheme.breakpoints.down('sm'));

  let token;

  if (!isObjEmpty(authUser)) {
    token = authUser?.data.access_token;
  } else if (!isObjEmpty(auth)) {
    token = auth?.data.access_token;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (query && query.length < 2) {
      return;
    }

    const queryType = query.startsWith('@ask') ? 'bot_input' : 'user_conversation';
    const updatedQuery = query.replace('@ask', '');

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify({ message: updatedQuery, type: queryType }));
    }

    setQuery('');
    setLoading(false);
  };

  const getAllChats = async (session_id) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const page = 1;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/chats?session_id=${session_id}&page=${page}`,
        { headers },
      );

      if (response.statusText === 'OK') {
        console.log(response.data.data.messages);
        dispatch(addToHistory(response.data.data.messages));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createSession = async () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/session?enterprise_id=${enterpriseID}`,
        {},
        { headers },
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getEnterpriseList = async () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/all-enterprises`, { headers });

      if (response.statusText === 'OK') {
        const resData = response.data.data?.enterprises;
        setEnterprise((prevState) => [...prevState, ...resData]);
      }
    } catch (error) {
      console.error(`Error from getEnterpriseList ${error}`);
    }
  };

  useEffect(() => {
    getEnterpriseList();
  }, []);

  useEffect(() => {
    socketRef.current = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_BASE_API_URL}/ws/${id}/${
        isObjEmpty(auth) ? authUser.data.user_id : auth.data.user_id
      }/${enterpriseID}`,
    );

    socketRef.current.onopen = () => {};

    socketRef.current.onmessage = (event) => {
      const message = event.data;

      const msgObj = JSON.parse(message);

      if (msgObj.type === 'start') {
        setLoading(true);
        botIDref.current = uuidv4();
      }

      if (msgObj.type === 'stream') {
        if (msgObj.sender_type === 'ENTERPRISE_ADMIN') {
          dispatch(
            appendHistory({
              _id: uuidv4(),
              message: msgObj.message,
              user: [
                {
                  user_type: 'ENTERPRISE_ADMIN',
                },
              ],
            }),
          );
        }
        if (msgObj.sender_type === 'USER') {
          dispatch(
            appendHistory({
              _id: uuidv4(),
              message: msgObj.message,
              user: [
                {
                  user_type: 'USER',
                },
              ],
            }),
          );
        }
        if (msgObj.sender_type === 'BOT') {
          if (!botIDref.current) botIDref.current = uuidv4();
          dispatch(
            appendHistory({
              _id: botIDref.current,
              message: msgObj.message,
              user: [
                {
                  user_type: 'BOT',
                },
              ],
            }),
          );
        }
        if (msgObj.sender_type === 'ENTERPRISE_EXPERT') {
          dispatch(
            appendHistory({
              _id: uuidv4(),
              message: msgObj.message,
              user: [
                {
                  user_type: 'ENTERPRISE_EXPERT',
                },
              ],
            }),
          );
        }
      }

      if (msgObj.type === 'end') {
        botIDref.current = null;
        setLoading(false);
        setQuery('');
      }
    };

    socketRef.current.onclose = () => {
      console.log('close');
    };

    return () => {
      if (socketRef.readyState === 1) {
        socketRef.close();
      }
    };
  }, [query, id, enterprise]);

  useEffect(() => {
    getAllChats(id);
  }, [id]);

  const drawer = <SideDrawer createSession={createSession} getAllChats={getAllChats} enterprise={enterprise} />;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {isSmallScreen && (
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            backgroundColor: 'secondary.light',
            color: 'white',
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="recent-chats">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, border: 'none' },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: 'chat.primary',
          minHeight: '100vh',
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          paddingTop: { xs: '3rem', sm: '0' },
          paddingBottom: '5rem',
        }}
      >
        {' '}
        <div>
          {chatHistory.length > 0 &&
            chatHistory.map((msg) => (
              <Box key={msg.id} sx={{ color: 'white' }}>
                {msg.user[0].user_type === 'BOT' ? (
                  <BotResponse response={msg.message} />
                ) : (
                  <UserQuery userQuery={msg.message} />
                )}
              </Box>
            ))}
        </div>
      </Box>
      <FormWrapper>
        <form onSubmit={(e) => handleSubmit(e)}>
          <InputWrapper>
            <input type="text" value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Write a prompt" />
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack
                bgcolor={loading ? 'transparent' : 'primary.main'}
                color="secondary.main"
                padding="5px"
                sx={{ borderRadius: '5px' }}
              >
                {loading ? (
                  <BeatLoader
                    color={theme.palette.primary.main}
                    loading={loading}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  <SendIcon onClick={(e) => handleSubmit(e)} sx={{ cursor: 'pointer' }} />
                )}
              </Stack>
            </Stack>
          </InputWrapper>
        </form>
      </FormWrapper>
    </Box>
  );
};

export default ChatHistory;
