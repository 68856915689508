import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddBoxIcon from '@mui/icons-material/AddBox';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import { DateTime } from 'luxon';
import { BottomBar, DrawerWrapper } from '../../pages/Chat/style';
import { userLogOut, enterpriseLogOut, storeSession } from '../../redux/authSlice';
import { addId, addToQuestions } from '../../redux/enterpriseSlice';
import SelectComponent from '../SelectComponent';
import { addToRecentChat, clearChat } from '../../redux/chatSlice';
import isObjEmpty from '../../utils/isObjEmpty';
import { showToast } from '../../utils/showToast';

const SideDrawer = ({ createSession, getAllChats, enterprise }) => {
  const [selectedEnterprise] = useState('');
  const [uniqueEnterpriseList, setUniqueEnterpriseList] = useState();
  const [showLogout, setShowLogout] = useState(false);

  const chats = useSelector((state) => state.chatSlice.recentChat);
  const auth = useSelector((state) => state.authSlice.enterprise);
  const authUser = useSelector((state) => state.authSlice.user);
  const enterpriseID = useSelector((state) => state.enterpriseSlice.id);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sortedChatData = [...chats];

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${isObjEmpty(auth) ? authUser?.data.access_token : auth?.data.access_token}`,
  };

  const sortedChat = sortedChatData.slice().sort((a, b) => {
    const timeA = DateTime.fromMillis(a.last_message_updated_at);
    const timeB = DateTime.fromMillis(b.last_message_updated_at);

    if (timeA < timeB) {
      return 1;
    }

    if (timeA > timeB) {
      return -1;
    }

    return 0;
  });

  const uniqueEnterprise = () => {
    const uniqueSet = new Set(enterprise?.map(JSON.stringify));
    const enterpriseList = Array.from(uniqueSet).map(JSON.parse);
    setUniqueEnterpriseList(enterpriseList);
  };

  const handleClick = (session_id) => {
    dispatch(storeSession(session_id));
    getAllChats(session_id);
    navigate(`/chat/${session_id}`);
  };

  const handleNewChat = () => {
    createSession();
    dispatch(clearChat());
    navigate('/chat');
  };

  const handleLogout = () => {
    if (!isObjEmpty(authUser)) {
      dispatch(userLogOut());
    } else if (!isObjEmpty(auth)) {
      dispatch(enterpriseLogOut());
    }
    dispatch(addId(''));
    dispatch(storeSession(''));
    navigate('/');
  };

  const getEnterpriseDetails = async (eid) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/enterprise`, {
        params: {
          record_id: eid,
        },
        headers,
      });

      dispatch(addId(res.data.data.enterprise._id));
      if (res.data.data.vector_questions[0].questions) {
        dispatch(addToQuestions(res.data.data.vector_questions[0].questions));
      } else {
        dispatch(addToQuestions([]));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    uniqueEnterprise();
    if (enterpriseID) {
      getEnterpriseDetails(enterpriseID);
    }
  }, [enterprise, selectedEnterprise, enterpriseID]);

  const getSession = async () => {
    const page = 1;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/session?page=${page}&enterprise_id=${enterpriseID}`,
        {
          headers,
        },
      );

      if (response.statusText === 'OK') {
        const { data } = response.data;
        dispatch(addToRecentChat(data));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (e, session_id) => {
    e.stopPropagation();

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/user-session?session_id=${session_id}`,
        {
          headers,
        },
      );
      if (response.statusText === 'OK') {
        getSession();
        showToast('Session Deleted!!!', 'success');

        navigate('/chat');
      }
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <DrawerWrapper>
      <Stack width="100%" p={2} direction="row" alignItems="center" justifyContent="space-between">
        <Button onClick={handleNewChat}>
          <HomeRoundedIcon color="primary" sx={{ cursor: 'pointer' }} />
        </Button>
        <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: 'black', color: 'white' }}>
          {uniqueEnterpriseList && uniqueEnterpriseList.length > 0 && (
            <SelectComponent
              defaultSelectedValue={enterpriseID}
              uniqueEnterpriseList={uniqueEnterpriseList}
              createSession={createSession}
            />
          )}
        </Box>
      </Stack>

      <Divider orientation="horizontal" sx={{ backgroundColor: 'text.grey200', height: '1.5px', width: '100%' }} />
      <Box flexGrow={1} sx={{ overflow: 'auto', width: '100%' }}>
        <Typography sx={{ padding: '1rem' }} textAlign="left" variant="subtitle1">
          Recent Chats
        </Typography>
        <Box>
          {sortedChat.map((chat) => {
            const dateTime = DateTime.fromMillis(chat.last_message_updated_at);
            const diff = Math.abs(dateTime.diffNow('seconds').as('seconds'));
            const time = diff <= 5 ? 'in a few seconds' : dateTime.toRelative();

            return (
              <Box
                key={chat.last_message_updated_at}
                textAlign="left"
                sx={{ padding: '1rem', cursor: 'pointer', ':hover': { backgroundColor: 'chat.lighter' } }}
                onClick={() => handleClick(chat.session_id)}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
                    {`${`${chat.session_name.substring(0, 15)}...`}`}
                  </Typography>
                  <Typography variant="caption" sx={{ color: 'text.primary' }}>
                    {time}
                  </Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between" alignItems="start">
                  <Typography variant="caption" color="chat.whiter">
                    {`${`${chat.first_message.substring(0, 60)}...`}`}
                  </Typography>
                  <IconButton aria-label="delete" onClick={(e) => handleDelete(e, chat.session_id)}>
                    <DeleteIcon color="primary" fontSize="small" />
                  </IconButton>
                </Stack>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Divider orientation="horizontal" sx={{ backgroundColor: 'text.grey200', height: '1.5px', width: '100%' }} />
      <BottomBar>
        <Stack sx={{ width: '250px' }}>
          <Button
            variant="outlined"
            onClick={handleNewChat}
            fullWidth
            sx={{
              textTransform: 'capitalize',
              fontWeight: 500,

              '& .MuiSvgIcon-root': { fontSize: '1.5rem' },
            }}
            startIcon={<AddBoxIcon />}
          >
            New Chat
          </Button>

          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{
              backgroundColor: 'text.grey400',
              padding: '1rem 2rem',
              mt: '1rem',
              border: (theme) => `1px solid ${theme.palette.text.chatBorder}`,
              borderRadius: '5px',
            }}
          >
            <Box flexGrow={1} sx={{ width: '100px' }}>
              <Typography variant="body2" sx={{ overflowWrap: 'anywhere', textTransform: 'capitalize' }}>
                {auth?.email?.split('@')[0] || authUser?.email?.split('@')[0]}
              </Typography>
            </Box>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ cursor: 'pointer', position: 'relative' }}
              onClick={() => setShowLogout(!showLogout)}
            >
              <MoreVertIcon />
            </Stack>
            {showLogout && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                  position: 'absolute',
                  bottom: '11%',
                  right: '5%',
                  backgroundColor: 'text.grey400',
                  padding: '.5rem .75rem',
                  borderRadius: '4px',
                  border: (theme) => `1px solid ${theme.palette.text.chatBorder}`,
                  cursor: 'pointer',
                }}
                onClick={handleLogout}
              >
                <LogoutRoundedIcon color="primary" sx={{ mr: '10px' }} />
                <Typography variant="subtitle2">Logout</Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </BottomBar>
    </DrawerWrapper>
  );
};

SideDrawer.propTypes = {
  createSession: PropTypes.func.isRequired,
  getAllChats: PropTypes.func.isRequired,
  enterprise: PropTypes.array.isRequired,
};

export default SideDrawer;
