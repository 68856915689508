/* eslint-disable no-console */

import React, { useEffect, useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SendIcon from '@mui/icons-material/Send';
import { v4 as uuidv4 } from 'uuid';
import Toolbar from '@mui/material/Toolbar';
import axios from 'axios';
import { useParams } from 'react-router';
import { Stack, useMediaQuery, useTheme, Typography, Avatar } from '@mui/material';
import { BeatLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import { FormWrapper, InputWrapper } from './style';
import ExpertDrawer from '../../components/ExpertDrawer/ExpertDrawer';
import ExpertHeader from '../../components/ExpertHeader';
import { addToHistory, appendHistory } from '../../redux/chatSlice';
import BotResponse from '../../components/ChatBox/BotResponse';
import UserQuery from '../../components/ChatBox/UserQuery';

const drawerWidth = 300;

const ExpertChat = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');

  const { id } = useParams();

  const expert = useSelector((state) => state.authSlice.expert);
  const chatHistory = useSelector((state) => state.chatSlice.history);

  const token = expert && expert.data && expert.data.access_token;

  const dispatch = useDispatch();

  const socketRef = useRef(null);
  const botIDref = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (query && query.length < 2) {
      return;
    }

    const queryType = query.startsWith('@ask') ? 'bot_input' : 'user_conversation';
    const updatedQuery = query.replace('@ask', '');

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify({ message: updatedQuery, type: queryType }));
    }

    setLoading(false);
    setQuery('');
  };

  const getAllChats = async (session_id) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const page = 1;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/chats?session_id=${session_id}&page=${page}`,
        { headers },
      );

      if (response.statusText === 'OK') {
        dispatch(addToHistory(response.data.data.messages));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    socketRef.current = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_BASE_API_URL}/ws/${id}/${expert?.data?.user_id}/${expert?.data?.enterprise_id}`,
    );

    socketRef.current.onopen = () => {};

    socketRef.current.onmessage = (event) => {
      const message = event.data;

      const msgObj = JSON.parse(message);

      if (msgObj.type === 'start') {
        console.log('START');
        setLoading(true);
        botIDref.current = uuidv4();
      }

      if (msgObj.type === 'stream') {
        if (msgObj.sender_type === 'ENTERPRISE_ADMIN') {
          dispatch(
            appendHistory({
              _id: uuidv4(),
              message: msgObj.message,
              user: [
                {
                  user_type: 'ENTERPRISE_ADMIN',
                },
              ],
            }),
          );
        }
        if (msgObj.sender_type === 'USER') {
          console.log('USER');
          dispatch(
            appendHistory({
              _id: uuidv4(),
              message: msgObj.message,
              user: [
                {
                  user_type: 'USER',
                },
              ],
            }),
          );
        }
        if (msgObj.sender_type === 'BOT') {
          console.log('BOT');
          console.log(botIDref.current);
          console.log('MESSAGE OBJ', msgObj);
          if (!botIDref.current) botIDref.current = uuidv4();

          dispatch(
            appendHistory({
              _id: botIDref.current,
              message: msgObj.message,
              user: [
                {
                  user_type: 'BOT',
                },
              ],
            }),
          );
          console.log('HISTORY', chatHistory);
        }
        if (msgObj.sender_type === 'ENTERPRISE_EXPERT') {
          dispatch(
            appendHistory({
              _id: uuidv4(),
              message: msgObj.message,
              user: [
                {
                  user_type: 'ENTERPRISE_EXPERT',
                },
              ],
            }),
          );
        }
      }

      if (msgObj.type === 'end') {
        botIDref.current = null;
        console.log('END', botIDref);
        setLoading(false);
        setQuery('');
      }
    };

    socketRef.current.onclose = () => {
      console.log('CLOSE');
    };

    return () => {
      if (socketRef.readyState === 1) {
        socketRef.close();
      }
    };
  }, [chatHistory]);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = <ExpertDrawer getAllChats={getAllChats} />;

  useEffect(() => {
    getAllChats(id);
  }, [id]);

  return (
    <>
      <ExpertHeader />{' '}
      <Box sx={{ display: 'flex', paddingTop: '8vh' }}>
        <CssBaseline />
        {isSmallScreen && (
          <AppBar
            position="fixed"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
              backgroundColor: 'secondary.light',
              color: 'white',
            }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        )}
        <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="recent-chats">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, paddingTop: '8vh' },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },

              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, border: 'none', marginTop: '8vh' },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            backgroundColor: 'chat.primary',
            minHeight: '92vh',
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <AppBar
              position="fixed"
              sx={{
                backgroundColor: 'secondary.light',
                color: 'white',
                padding: '5px',
                top: '8vh',
                width: 'calc(100% - 300px)',
              }}
            >
              <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Stack direction="row" alignItems="center" justifyContent="center" gap={2}>
                  <Avatar />
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography variant="body2">{chatHistory[0]?.user[0]?.username}</Typography>
                    <Typography variant="caption">{chatHistory[0]?.user[0]?.email}</Typography>
                  </Box>
                </Stack>
              </Toolbar>
            </AppBar>
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              backgroundColor: 'chat.primary',
              maxHeight: '80vh',
              overflowY: 'auto',
              paddingTop: { xs: '3rem', sm: '10vh' },
              paddingBottom: '1rem',
            }}
          >
            <div>
              {chatHistory.length > 0 &&
                chatHistory.map((msg) => (
                  <Box key={msg.id} sx={{ color: 'white' }}>
                    {msg.user[0].user_type === 'BOT' ? (
                      <BotResponse response={msg.message} />
                    ) : (
                      <UserQuery userQuery={msg.message} />
                    )}
                  </Box>
                ))}
            </div>
          </Box>
        </Box>
        <FormWrapper>
          <form onSubmit={(e) => handleSubmit(e)}>
            <InputWrapper>
              <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Write a prompt"
              />
              <Stack direction="row" alignItems="center" spacing={2}>
                <Stack
                  bgcolor={loading ? 'transparent' : 'primary.main'}
                  color="secondary.main"
                  padding="5px"
                  sx={{ borderRadius: '5px' }}
                >
                  {loading ? (
                    <BeatLoader
                      color={theme.palette.primary.main}
                      loading={loading}
                      size={10}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    <SendIcon onClick={(e) => handleSubmit(e)} sx={{ cursor: 'pointer' }} />
                  )}
                </Stack>
              </Stack>
            </InputWrapper>
          </form>
        </FormWrapper>
      </Box>
    </>
  );
};

export default ExpertChat;
