import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import userIcon from '../../assets/userIcon.png';

const UserQuery = ({ userQuery }) => (
  <Box
    sx={{
      backgroundColor: 'text.grey400',
      borderRadius: '8px',
      padding: '1rem',
      width: '95%',
      my: '1.5rem ',
      display: 'flex',
      alignItems: 'flex-start',
    }}
  >
    <img src={userIcon} alt="" style={{ marginRight: '1rem' }} />
    <Typography mt={0.5} textAlign="left">
      {userQuery}
    </Typography>
  </Box>
);

UserQuery.propTypes = {
  userQuery: PropTypes.string.isRequired,
};

export default UserQuery;
