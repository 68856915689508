import styled from '@emotion/styled';

export const IllustrationWrapper = styled.img`
  height: 15rem;
  padding: 2rem 0;

  @media (max-width: 475px) {
    height: 10rem;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  padding: 6rem 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 2rem;
`;
