import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Avatar, Menu, MenuItem, Stack } from '@mui/material';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/mainLogo.png';
import { expertLogOut } from '../redux/authSlice';
import { showToast } from '../utils/showToast';

const ExpertHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorElSec, setAnchorElSec] = React.useState(null);
  const openRight = Boolean(anchorElSec);

  const handleClick = (event) => {
    setAnchorElSec(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElSec(null);
  };

  const handleLogout = () => {
    dispatch(expertLogOut());
    showToast('Logged out successfully', 'success');
    navigate('/expert-login');
  };

  return (
    <AppBar
      elevation={0}
      position="fixed"
      sx={{
        bgcolor: 'secondary.light',
        zIndex: 9999,
        height: '8vh',
        borderBottom: (theme) => `1px solid ${theme.palette.text.grey200}`,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <img
              src={logo}
              alt="Logo"
              style={{ marginRight: 2, height: 40, cursor: 'pointer' }}
              onClick={() => navigate('/')}
            />
          </Stack>

          <Box sx={{ flexGrow: 0, cursor: 'pointer' }} onClick={handleClick}>
            <Avatar />
          </Box>
          <Menu
            anchorEl={anchorElSec}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            open={openRight}
            onClose={handleClose}
            onClick={handleClose}
            sx={{
              mt: '3%',
              '& .MuiPaper-root': {
                backgroundColor: 'secondary.blackShade',
              },
            }}
          >
            <MenuItem sx={{ fontSize: '.9rem' }} onClick={handleLogout}>
              <LogoutRoundedIcon sx={{ mr: '2px' }} />
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ExpertHeader;
