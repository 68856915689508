import styled from '@emotion/styled';

export const TextWrapper = styled.div`
  text-align: center;
  color: white;
  max-width: 700px;
`;

export const BannerWrapper = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.palette.secondary.light};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10rem;

  @media (max-width: 1024px) {
    gap: 5rem;
  }

  @media (max-width: 475px) {
    max-width: 350px;
    margin: 0 auto;
  }
`;

export const ImgWrapper = styled.img`
  margin-top: -5%;
  width: 95%;
  @media (max-width: 475px) {
    margin-top: -1%;
    max-width: 375px;
  }
`;

export const FeatureWrapper = styled.div`
  padding: 5rem 0;
`;

export const CardWrapper = styled.div`
  margin-top: 5%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 3rem 2rem;
`;

export const Card = styled.div`
  max-width: 350px;
`;

export const CardGradient = styled.div`
  position: absolute;
  width: 300px;
  height: 300px;
  left: 30%;
  top: 10%;

  background: linear-gradient(98.24deg, #e9fe53 0%, #7c8825 100%);
  filter: blur(105px);
  transform: rotate(-68.53deg);

  @media (max-width: 475px) {
    display: none;
  }
`;

export const PriceWrapper = styled.div`
  /* margin: 7rem 0; */
  padding: 5rem 0;
`;

export const PriceCardWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
  margin-top: 5%;
`;

export const PriceCard = styled.div`
  width: 300px;
  height: 350px;
  background-color: ${({ theme }) => theme.palette.text.grey400};
  border-radius: 15px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid white;
  z-index: 1;
`;

export const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  border-top: 2px solid ${({ theme }) => theme.palette.text.grey300};
  padding: 1rem;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }
`;
