import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { addId, addToQuestions } from '../redux/enterpriseSlice';
import isObjEmpty from '../utils/isObjEmpty';

function SelectComponent({ defaultSelectedValue, uniqueEnterpriseList, createSession }) {
  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);

  const auth = useSelector((state) => state.authSlice.enterprise);
  const authUser = useSelector((state) => state.authSlice.user);
  const dispatch = useDispatch();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${isObjEmpty(auth) ? authUser?.data.access_token : auth?.data.access_token}`,
  };

  const getEnterpriseDetails = async (id) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/enterprise`, {
        params: {
          record_id: id,
        },
        headers,
      });

      dispatch(addToQuestions(res.data.data.vector_questions[0].questions));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDropdownChange = (event) => {
    createSession();
    setSelectedValue(event.target.value);
    dispatch(addId(event.target.value));
    getEnterpriseDetails(event.target.value);
  };

  return (
    <FormControl
      size="small"
      sx={{ width: '180px', '& .MuiMenu-paper': { backgroundColor: (theme) => theme.palette.secondary.main } }}
    >
      {' '}
      <InputLabel id="demo-simple-select-label">Enterprise</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedValue}
        onChange={handleDropdownChange}
      >
        {uniqueEnterpriseList && uniqueEnterpriseList.length > 0 ? (
          uniqueEnterpriseList.map((item) => (
            <MenuItem sx={{ color: 'grey' }} key={item._id} value={item._id}>
              {item.business_name}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="">No options available</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

SelectComponent.propTypes = {
  createSession: PropTypes.func.isRequired,
  uniqueEnterpriseList: PropTypes.array.isRequired,
  defaultSelectedValue: PropTypes.string.isRequired,
};

export default SelectComponent;
