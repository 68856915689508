import styled from '@emotion/styled';

export const DrawerWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.light};
  color: white;
  height: 100vh;
  display: flex;

  flex-direction: column;
  align-items: flex-start;
`;

export const BottomBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 0;
  padding-left: 0.25rem;
`;

export const FormWrapper = styled.div`
  position: fixed;
  bottom: 2%;
  right: 4%;
  min-width: calc(100% - 400px);

  @media (max-width: 600px) {
    width: 95%;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 30px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.chat.light};
  border: 1px solid ${({ theme }) => theme.palette.chat.secondary};

  input {
    background-color: ${({ theme }) => theme.palette.chat.light};
    color: white;
    border: none;
    outline: none;
    font-size: 16px;
    width: 100%;

    ::placeholder {
      color: ${({ theme }) => theme.palette.chat.text};
      font-size: 16px;
    }
  }
`;
