import { createTheme, ThemeProvider } from '@mui/material';
import PropTypes from 'prop-types';

const theme = createTheme({
  palette: {
    primary: {
      main: '#E9FE53',
    },
    secondary: {
      main: '#070707',
      light: '#141414',
      blackShade: '#1C1D23',
      progressBg: '#070707',
    },
    text: {
      primary: '#BBBBBB',
      secondary: '#4E596F',
      grey: '#888888',
      grey100: '#B1B1B1',
      grey200: '#363636',
      grey300: '#343434',
      grey400: '#1D1D1D',
      lightGrey: '#D9D9D9',
      chatBorder: '#454545',
    },
    chat: {
      primary: '#111111',
      secondary: '#454545',
      light: '#252527',
      lighter: '#262626',
      text: '#535353',
      whiter: '#D1D1D1',
    },
  },
  typography: {
    fontFamily: ['Poppins, sans-serif'],
  },
});

const CustomThemeProvider = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

CustomThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomThemeProvider;
