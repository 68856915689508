import React from 'react';
import styled from '@emotion/styled';
import Navbar from '../../components/Navbar';
import LandingPage from '../../components/LandingPage';

const HeroWrapper = styled.div`
  color: white;
  background-color: ${({ theme }) => theme.palette.secondary.light};
  margin-top: 1%;

  @media (max-width: 475px) {
    margin-top: 5%;
  }
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.light};
  height: 100vh;
`;

const Home = () => (
  <Wrapper>
    <>
      <Navbar />
      <HeroWrapper>
        <LandingPage />
      </HeroWrapper>
    </>
  </Wrapper>
);

export default Home;
