import styled from '@emotion/styled';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom/dist';
import axios from 'axios';
import { SHA1 } from 'crypto-js';
import { useDispatch } from 'react-redux';
import hero from '../../assets/Group 1000001775.png';
import logo from '../../assets/logo.png';
import { loginEnterprise } from '../../redux/authSlice';
import { showToast } from '../../utils/showToast';
import { addId } from '../../redux/enterpriseSlice';

const LoginWrapper = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

const ContentWrapper = styled.div`
  color: white;
  height: 100vh;
  display: grid;
  place-items: center;
`;
const TextWrapper = styled.div`
  padding: 2.5rem;
  height: 100%;

  @media (max-width: 475px) {
    padding: 1.25rem;
  }
`;

const ImageWrapper = styled.img`
  width: 300px;
  height: 300px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const hashPwd = SHA1(password).toString();
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/user/signin`,

        {
          email,
          password: hashPwd,
        },
      );

      if (response.statusText === 'OK') {
        dispatch(loginEnterprise({ data: response.data.data, email }));
        dispatch(addId(response.data.data.enterprise_id));
        showToast('Logged in successfully', 'success');
        setLoading(false);

        navigate('/train-ai');
      }
    } catch (error) {
      showToast('Something went wrong!', 'error');
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <LoginWrapper>
      <ContentWrapper>
        <Box
          sx={{ width: { xs: '350px', sm: '500px', md: '800px' }, bgcolor: 'secondary.light', borderRadius: '16px' }}
        >
          <TextWrapper>
            <img
              src={logo}
              alt="Logo"
              style={{ marginBottom: '2rem', height: 70, display: 'flex', alignItems: 'flex-start' }}
            />
            <Typography
              letterSpacing="1px"
              textAlign="left"
              variant="h4"
              fontWeight="600"
              mb=".5rem"
              sx={{ fontSize: { xs: '20px', sm: '35px' } }}
            >
              Enterprise Login
            </Typography>
            <Stack direction="row" alignItems="center" gap="2rem" justifyContent="space-around">
              <form onSubmit={(e) => handleLogin(e)}>
                <TextField
                  id="email"
                  value={email}
                  label="Email"
                  variant="filled"
                  fullWidth
                  sx={{ marginY: '.5rem' }}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  type="password"
                  value={password}
                  id="password"
                  label="Password"
                  variant="filled"
                  fullWidth
                  sx={{ marginY: '.5rem' }}
                  onChange={(e) => setPassword(e.target.value)}
                />

                <Stack>
                  <Button variant="contained" onClick={handleLogin}>
                    Login
                  </Button>
                  <Typography variant="caption" textAlign="left" sx={{ color: 'white', fontWeight: 500, mt: '.75rem' }}>
                    Don&apos;t have an account?{' '}
                    <Typography
                      variant="caption"
                      component="span"
                      sx={{ textDecoration: 'underline', color: 'text.grey100', cursor: 'pointer' }}
                      onClick={() => navigate('/signup')}
                    >
                      Sign up
                    </Typography>
                  </Typography>
                </Stack>
              </form>
              <div>
                <ImageWrapper src={hero} alt="" />
              </div>
            </Stack>
          </TextWrapper>
        </Box>
      </ContentWrapper>
    </LoginWrapper>
  );
};

export default Login;
