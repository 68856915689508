import { DateTime } from 'luxon';

export const formatTimestamp = (timestamp) => {
  const dateTime = DateTime.fromMillis(timestamp);
  return dateTime.toFormat('dd/MM/yyyy');
};

export const sortChats = (chats) => {
  const sortedChats = chats.slice().sort((a, b) => {
    const timeA = DateTime.fromMillis(a.last_message_updated_at);
    const timeB = DateTime.fromMillis(b.last_message_updated_at);

    if (timeA < timeB) {
      return 1;
    }

    if (timeA > timeB) {
      return -1;
    }

    return 0;
  });

  return sortedChats;
};
