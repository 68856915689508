import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  enterprise: {},
  user: {},
  expert: {},
  session: {
    id: '',
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUser: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    loginEnterprise: (state, action) => ({
      ...state,
      enterprise: { ...action.payload },
    }),
    loginExpert: (state, action) => ({
      ...state,
      expert: { ...action.payload },
    }),
    storeSession: (state, action) => ({
      ...state,
      session: { id: action.payload },
    }),
    enterpriseLogOut: (state) => ({
      ...state,
      enterprise: {},
    }),
    userLogOut: (state) => ({
      ...state,
      user: {},
    }),
    expertLogOut: (state) => ({
      ...state,
      expert: {},
    }),
  },
});

export const { storeSession, loginEnterprise, loginExpert, loginUser, enterpriseLogOut, userLogOut, expertLogOut } =
  authSlice.actions;

export default authSlice.reducer;
