/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import styled from '@emotion/styled';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';
import { SHA1 } from 'crypto-js';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom/dist';
import hero from '../../assets/Group 1000001775.png';
import logo from '../../assets/logo.png';
import { showToast } from '../../utils/showToast';

const LoginWrapper = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

const ContentWrapper = styled.div`
  color: white;
  height: 100vh;
  display: grid;
  place-items: center;
`;
const TextWrapper = styled.div`
  padding: 2.5rem;
  height: 100%;

  @media (max-width: 475px) {
    padding: 1.25rem;
  }
`;

const ImageWrapper = styled.img`
  width: 300px;
  height: 300px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ExpertSignup = () => {
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const token = params.get('auth');

  console.log(token);

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  console.log(token);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isChecked) {
      if (password === confirmPassword) {
        const hashPwd = SHA1(password).toString();

        const data = {
          password: hashPwd,
        };

        const formData = JSON.stringify(data);
        console.log(formData, data);

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_API_URL}/api/v1/reset-password-expert`,
            formData,
            {
              headers,
            },
          );
          console.log(response);

          if (response.statusText === 'OK') {
            showToast('Password reset successful', 'success');
            navigate('/expert-login');
          }
        } catch (error) {
          showToast('Something went wrong!', 'error');
          console.error(error);
          setLoading(false);
        }
      } else {
        showToast('Password not matched', 'error');
      }
    } else {
      showToast('Please check the Terms & Privacy Policy!', 'error');
    }
  };

  return (
    <LoginWrapper>
      <ContentWrapper>
        <Box
          sx={{ width: { xs: '350px', sm: '500px', md: '800px' }, bgcolor: 'secondary.light', borderRadius: '16px' }}
        >
          <TextWrapper>
            <img
              src={logo}
              alt="Logo"
              style={{ marginBottom: '2rem', height: 70, display: 'flex', alignItems: 'flex-start' }}
            />
            <Typography
              letterSpacing="1px"
              textAlign="left"
              variant="h4"
              fontWeight="400"
              mb=".5rem"
              sx={{ fontSize: { xs: '20px', sm: '35px' } }}
            >
              Join our experts portal
            </Typography>
            <Stack direction="row" alignItems="center" gap="2rem" justifyContent="space-around">
              <form>
                <TextField
                  id="password"
                  value={password}
                  type="password"
                  label="Create Password"
                  variant="filled"
                  fullWidth
                  sx={{ marginY: '.5rem' }}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                  type="password"
                  value={confirmPassword}
                  id="confirmPassword"
                  label="Re-enter Password"
                  variant="filled"
                  fullWidth
                  sx={{ marginY: '.5rem' }}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />

                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />}
                    label="I agree to Terms & Privacy Policy "
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, '& .MuiFormControlLabel-label': { fontSize: 14 } }}
                  />
                </FormGroup>

                <Stack mt={2}>
                  <Button variant="contained" onClick={handleSubmit}>
                    Sign Up
                  </Button>
                </Stack>
              </form>
              <div>
                <ImageWrapper src={hero} alt="" />
              </div>
            </Stack>
          </TextWrapper>
        </Box>
      </ContentWrapper>
    </LoginWrapper>
  );
};

export default ExpertSignup;
