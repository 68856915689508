import React from 'react';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Stack, TextField, Typography } from '@mui/material';
import styled from '@emotion/styled';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DemoHeader from '../../components/DemoHeader';
import hero from '../../assets/Group 1000001775.png';

const BookDemoWrapper = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  padding-top: 12vh;
`;

const ContentWrapper = styled.div`
  color: white;
  height: 90vh;
  display: grid;
  place-items: center;
`;

const TextWrapper = styled.div`
  padding: 2.5rem;
  height: 100%;

  @media (max-width: 475px) {
    padding: 1.25rem;
  }
`;

const ImageWrapper = styled.img`
  width: 300px;
  height: 300px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const BookDemo = () => (
  <BookDemoWrapper>
    <DemoHeader />
    <ContentWrapper>
      <Box
        sx={{
          width: { xs: '300px', sm: '500px', md: '800px' },
          bgcolor: 'secondary.light',
          borderRadius: '16px',
        }}
      >
        <TextWrapper>
          <Typography
            letterSpacing="1px"
            textAlign="left"
            variant="h4"
            fontWeight="600"
            mb="1rem"
            sx={{ fontSize: { xs: '20px', sm: '30px' } }}
          >
            Book Demo
          </Typography>
          <Stack direction="row" alignItems="center" gap="2rem" justifyContent="space-around">
            <form>
              <TextField id="fullName" label="Full Name" variant="filled" fullWidth sx={{ marginY: '.5rem' }} />
              <TextField id="companyName" label="Company Name" variant="filled" fullWidth sx={{ marginY: '.5rem' }} />
              <TextField id="designation" label="Designation" variant="filled" fullWidth sx={{ marginY: '.5rem' }} />
              <TextField multiline rows={4} label="Requirements" variant="filled" fullWidth sx={{ marginY: '.5rem' }} />
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="I am not a robot"
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, '& .MuiFormControlLabel-label': { fontSize: 14 } }}
                />
              </FormGroup>
              <Button
                sx={{ textTransform: 'capitalize', fontWeight: '500', float: 'left', marginTop: '1.25rem' }}
                variant="contained"
                startIcon={<CalendarMonthOutlinedIcon />}
              >
                Book Demo
              </Button>
            </form>
            <ImageWrapper src={hero} alt="" className="" />
          </Stack>
        </TextWrapper>
      </Box>
    </ContentWrapper>
  </BookDemoWrapper>
);

export default BookDemo;
