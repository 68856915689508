import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import isObjEmpty from '../../utils/isObjEmpty';

const ChatDashboard = ({ setQuery, questions }) => {
  const navigate = useNavigate();
  const authUser = useSelector((state) => state.authSlice.user);

  const handleClick = (inputPrompt) => {
    const trimmedPrompt = inputPrompt.split('.').slice(1).join('.').trim();
    setQuery(`@ask ${trimmedPrompt}`);
  };

  return (
    <Stack sx={{ height: '100%' }} alignItems="center" justifyContent="center">
      {' '}
      <Box sx={{ width: { xs: '100%', sm: '60%' }, color: 'white' }}>
        {isObjEmpty(authUser) && (
          <>
            <Typography textAlign="left" fontFamily="Roboto">
              Examples
            </Typography>
            {!questions.length > 0 && (
              <Typography variant="h5" textAlign="left" fontFamily="Roboto">
                You don&apos;t have the data trained.
                <Typography
                  style={{
                    color: 'text.grey',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() => navigate('/train-ai')}
                >
                  Train it here
                </Typography>
              </Typography>
            )}
          </>
        )}
        {questions.map((prompt) => (
          <Box
            key={prompt}
            sx={{
              backgroundColor: 'secondary.blackShade',
              borderRadius: '16px',
              padding: '1rem',
              my: '1.5rem',
              cursor: 'pointer',
              transition: 'ease-in',
              '&:hover': { transform: 'scale(1.1)' },
            }}
            onClick={() => handleClick(prompt)}
          >
            <Typography textAlign="left" fontFamily="Roboto">
              {prompt}
            </Typography>
          </Box>
        ))}
      </Box>
    </Stack>
  );
};

ChatDashboard.propTypes = {
  questions: PropTypes.array.isRequired,
  setQuery: PropTypes.func.isRequired,
};

export default ChatDashboard;
