/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import styled from '@emotion/styled';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom/dist';
import axios from 'axios';
import { SHA1 } from 'crypto-js';
import { useDispatch } from 'react-redux';
import hero from '../../assets/Group 1000001775.png';
import logo from '../../assets/logo.png';
import { loginExpert, loginUser } from '../../redux/authSlice';
import { showToast } from '../../utils/showToast';

const LoginWrapper = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

const ContentWrapper = styled.div`
  color: white;
  height: 100vh;
  display: grid;
  place-items: center;
`;
const TextWrapper = styled.div`
  padding: 2.5rem;
  height: 100%;

  @media (max-width: 475px) {
    padding: 1.25rem;
  }
`;

const ImageWrapper = styled.img`
  width: 300px;
  height: 300px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ExpertLogin = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const hashPwd = SHA1(password).toString();
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/user/signin`,

        {
          email,
          password: hashPwd,
        },
      );
      console.log(response);

      if (response.statusText === 'OK') {
        console.log(response);
        dispatch(loginExpert({ data: response.data.data, email }));
        setLoading(false);
        showToast('Logged in successfully', 'success');
        navigate('/expert-dashboard');
      }
    } catch (error) {
      showToast('Something went wrong!', 'error');
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <LoginWrapper>
      <ContentWrapper>
        <Box
          sx={{ width: { xs: '350px', sm: '500px', md: '800px' }, bgcolor: 'secondary.light', borderRadius: '16px' }}
        >
          <TextWrapper>
            <img
              src={logo}
              alt="Logo"
              style={{ marginBottom: '2rem', height: 70, display: 'flex', alignItems: 'flex-start' }}
            />
            <Typography
              letterSpacing="1px"
              textAlign="left"
              variant="h4"
              fontWeight="600"
              mb=".5rem"
              sx={{ fontSize: { xs: '20px', sm: '35px' } }}
            >
              Welcome Back
            </Typography>
            <Typography
              letterSpacing=".5px"
              textAlign="left"
              variant="body2"
              fontWeight="400"
              color="text.primary"
              sx={{ fontSize: { xs: '12px', sm: '14px' } }}
            >
              Log in to access experts portal
            </Typography>
            <Stack direction="row" alignItems="center" gap="2rem" justifyContent="space-around">
              <form onSubmit={(e) => handleLogin(e)}>
                <TextField
                  id="email"
                  value={email}
                  label="Email"
                  variant="filled"
                  fullWidth
                  sx={{ marginY: '.5rem' }}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  type="password"
                  value={password}
                  id="password"
                  label="Password"
                  variant="filled"
                  fullWidth
                  sx={{ marginY: '.5rem' }}
                  onChange={(e) => setPassword(e.target.value)}
                />

                <Stack mt={2}>
                  <Button variant="contained" onClick={handleLogin}>
                    Login
                  </Button>
                </Stack>
              </form>
              <div>
                <ImageWrapper src={hero} alt="" />
              </div>
            </Stack>
          </TextWrapper>
        </Box>
      </ContentWrapper>
    </LoginWrapper>
  );
};

export default ExpertLogin;
