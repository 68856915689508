/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Stack, Typography, useTheme } from '@mui/material';
import styled from '@emotion/styled';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import SearchIcon from '@mui/icons-material/Search';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { DrawerWrapper } from '../../pages/Chat/style';
import { storeSession } from '../../redux/authSlice';
import { sortChats } from '../../utils/dateUtils';

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.secondary.blackShade};
  margin: 1rem 1rem;
  padding: 0.5rem 1rem;
  border-radius: px;
  width: 90%;

  input {
    background-color: ${({ theme }) => theme.palette.secondary.blackShade};
    border: none;
    outline: none;
    margin-right: auto;
    color: white;

    ::placeholder {
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }
`;

const ExpertDrawer = ({ getAllChats }) => {
  const [answered, setAnswered] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authSlice.expert);
  const theme = useTheme();

  const token = auth && auth.data && auth.data.access_token;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const getAllAnsweredChats = async () => {
    const page = 1;
    const res = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/answered-session?page=${page}`, {
      headers,
    });

    console.log(res);

    if (res.status === 200) {
      setAnswered(res.data.data);
    }
  };

  const handleClick = (session_id) => {
    dispatch(storeSession(session_id));
    getAllChats(session_id);
    navigate(`/expert-chat/${session_id}`);
  };

  const handleBack = () => {
    navigate(`/leads`);
  };

  useEffect(() => {
    getAllAnsweredChats();
  }, []);

  const sortedAnswered = answered && [...answered];
  const sortedAnsweredData = answered && sortChats(sortedAnswered);

  return (
    <DrawerWrapper style={{ borderRight: `1px solid ${theme.palette.text.grey200}` }}>
      <Stack width="100%" pt={2} direction="row" alignItems="center" justifyContent="space-between">
        <Button onClick={handleBack}>
          <KeyboardBackspaceRoundedIcon color="primary" sx={{ cursor: 'pointer' }} />
        </Button>
        <Typography variant="h6">Chat</Typography>
        <Box flexGrow={1} />
      </Stack>
      <InputWrapper>
        <SearchIcon sx={{ color: 'text.primary', fontSize: '1rem' }} />
        <input type="text" placeholder="Search" />
      </InputWrapper>
      <Divider orientation="horizontal" sx={{ backgroundColor: 'text.grey200', height: '1.5px', width: '100%' }} />
      <Box flexGrow={1} sx={{ overflow: 'auto', width: '100%' }}>
        {sortedAnsweredData &&
          sortedAnsweredData.map((ans) => {
            const dateTime = DateTime.fromMillis(ans.last_message_updated_at);
            const diff = Math.abs(dateTime.diffNow('seconds').as('seconds'));
            const time = diff <= 5 ? 'in a few seconds' : dateTime.toRelative();
            return (
              <Box
                key={ans.session_id}
                onClick={() => handleClick(ans.session_id)}
                textAlign="left"
                sx={{ padding: '1rem', cursor: 'pointer', ':hover': { backgroundColor: 'chat.lighter' } }}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
                    {`${`${ans.session_name.substring(0, 15)}...`}`}
                  </Typography>
                  <Typography variant="caption" sx={{ color: 'text.primary' }}>
                    {time}
                  </Typography>
                </Stack>
                <Typography variant="caption" color="chat.whiter">
                  {`${`${ans.first_message.substring(0, 80)}...`}`}
                </Typography>
              </Box>
            );
          })}
      </Box>
    </DrawerWrapper>
  );
};

ExpertDrawer.propTypes = {
  getAllChats: PropTypes.func.isRequired,
};

export default ExpertDrawer;
