import styled from '@emotion/styled';

/* eslint-disable import/prefer-default-export */
export const Wrapper = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 5%;
`;

export const Background = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;
