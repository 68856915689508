/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import { indigo } from '@mui/material/colors';
import { v4 as uuidv4 } from 'uuid';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SearchIcon from '@mui/icons-material/Search';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import uploadBot from '../../assets/uploadBot.png';
import { showToast } from '../../utils/showToast';

import TrainingAI from '../TrainingAI/TrainingAI';
import {
  ContentWrapper,
  CustomDataTable,
  FileInfo,
  FileInfoWrapper,
  ImgWrapper,
  InputWrapper,
  TableWrapper,
  TextWrapper,
  TrainAIWrapper,
  UploadFileWrapper,
} from './style';
import { addToQuestions } from '../../redux/enterpriseSlice';

export const DocumentTable = () => {
  const columns = [
    {
      name: 'FILE NAME',
      selector: (row) => row.file_name,
    },
    {
      name: 'FILE TYPE',
      selector: (row) => row.file_type,
    },
    {
      name: 'SIZE',
      selector: (row) => row.size,
    },
    {
      name: 'DATE MODIFIED',
      selector: (row) => row.date,
    },
    {
      name: 'STATUS',
      selector: (row) => row.status,
    },
  ];

  const data = [
    {
      id: 1,
      file_name: 'Company_intro_file.pdf',
      file_type: 'PDF',
      size: '15MB',
      date: 'May 22, 2022, 05:30 pm',
      status: 'Active',
    },
    {
      id: 2,
      file_name: 'Company_intro_file.pdf',
      file_type: 'PDF',
      size: '15MB',
      date: 'May 22, 2022, 05:30 pm',
      status: 'Active',
    },
    {
      id: 3,
      file_name: 'Company_intro_file.pdf',
      file_type: 'PDF',
      size: '15MB',
      date: 'May 22, 2022, 05:30 pm',
      status: 'Active',
    },
    {
      id: 4,
      file_name: 'Company_intro_file.pdf',
      file_type: 'PDF',
      size: '15MB',
      date: 'May 22, 2022, 05:30 pm',
      status: 'Active',
    },
  ];

  return (
    <TableWrapper>
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{ paddingBottom: '1rem', gap: '1rem' }}
        mb=".5rem"
        justifyContent="space-between"
      >
        <Typography variant="subtitle1" fontWeight={500} color="white">
          Dataset
        </Typography>
        <InputWrapper>
          <SearchIcon />
          <input type="text" placeholder="Search file name" />
        </InputWrapper>
      </Stack>
      <div style={{ height: '100%', width: '100%' }}>
        <CustomDataTable columns={columns} data={data} selectableRows />
      </div>
    </TableWrapper>
  );
};

const TrainAI = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [trainAI, setTrainAI] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filesKey, setFilesKey] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.authSlice.enterprise);

  const token = auth && auth.data && auth.data.access_token;

  const uploadFile = async (file) => {
    try {
      const uniqueKey = uuidv4();
      const fileExt = file.name.split('.').pop();

      const res = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/get_access_url`, {
        params: {
          key: `${uniqueKey}_${file.name}`,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.statusText === 'OK') {
        setFilesKey((prevKeys) => [...prevKeys, res.data.key]);
      }

      const headers = {
        'Content-Type': file.type,
        'Content-Length': file.size,
      };

      const uploadRes = await axios.put(res.data.url, file, headers);
    } catch (error) {
      showToast('Error uploading file!', 'error');
      console.error('Error uploading file:', file, error);
    }
  };

  const simulateFileUpload = (file, index) => {
    const interval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        const updatedProgress = [...prevProgress];
        const newProgress = updatedProgress[index] + 10;
        updatedProgress[index] = newProgress;
        if (newProgress >= 100) {
          clearInterval(interval);
          setLoading(false);
          showToast('File Uploaded!', 'success');
        }
        return updatedProgress;
      });
    }, 500);
  };

  const handleUpload = async (event) => {
    setLoading(true);
    const { files } = event.target;
    const updatedSelectedFiles = [...selectedFiles];
    const updatedUploadProgress = [...uploadProgress];

    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];

      uploadFile(file);

      updatedSelectedFiles.push(file);

      updatedUploadProgress.push(0);

      simulateFileUpload(file, updatedUploadProgress.length - 1);
    }

    setSelectedFiles(updatedSelectedFiles);
    setUploadProgress(updatedUploadProgress);
  };
  const handleTrainAI = async () => {
    setTrainAI(true);
    try {
      const sendFiles = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/v1/store_keys`, filesKey, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const createIndex = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/create-index`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (createIndex.status === 200) {
        const genQuestion = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/gen_question`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (genQuestion.status === 200) {
          dispatch(addToQuestions(genQuestion.data.data.questions));
          showToast('Data trained', 'success');
          setTrainAI(false);
          navigate(`/chat`);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <TrainAIWrapper>
      <ContentWrapper>
        <Box
          sx={{
            bgcolor: 'secondary.light',
            borderRadius: '16px',
            width: { xs: '80%', sm: '80%', lg: '80%' },
          }}
        >
          {!trainAI ? (
            <>
              <TextWrapper>
                <Typography letterSpacing="1px" textAlign="left" variant="subtitle2" fontWeight="500" mb=".5rem">
                  Upload Dataset to Train AI
                </Typography>
                {completed ? (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      textTransform: 'capitalize',
                      fontWeight: 600,
                      color: 'secondary.main',
                      '& .MuiSvgIcon-root': { fontSize: '1.5rem' },
                    }}
                    startIcon={<ArrowCircleRightIcon />}
                  >
                    Publish
                  </Button>
                ) : (
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        textTransform: 'capitalize',
                        fontWeight: 600,
                        color: 'secondary.main',
                        '& .MuiSvgIcon-root': { fontSize: '1.5rem' },
                      }}
                      startIcon={<RemoveRedEyeRoundedIcon />}
                      onClick={() => navigate('/chat')}
                    >
                      Preview
                    </Button>

                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        textTransform: 'capitalize',
                        fontWeight: 600,
                        color: 'secondary.main',
                        cursor: 'pointer',
                        '&:disabled': {
                          backgroundColor: 'text.primary',
                          color: 'text.grey400',
                          cursor: 'not-allowed',
                        },
                      }}
                      startIcon={<DataThresholdingIcon />}
                      onClick={handleTrainAI}
                      disabled={loading}
                    >
                      Train AI
                    </Button>
                  </Stack>
                )}
              </TextWrapper>
              <UploadFileWrapper>
                <Stack alignItems="center">
                  <Typography textAlign="center" variant="subtitle2" color="text.grey" fontWeight="500" mb=".25rem">
                    Drop multiple files here or
                  </Typography>
                  <input id="upload-input" type="file" style={{ display: 'none' }} onChange={handleUpload} multiple />
                  <label htmlFor="upload-input">
                    <Typography
                      textAlign="center"
                      variant="subtitle2"
                      color={indigo[600]}
                      fontWeight="500"
                      mb=".5rem"
                      component="span"
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'none',
                      }}
                    >
                      choose file
                    </Typography>
                  </label>
                  <Typography
                    maxWidth="250px"
                    textAlign="center"
                    variant="subtitle2"
                    color="text.lightGrey"
                    fontWeight="500"
                    mt="1rem"
                  >
                    Files upto max 25 MB per file .txt, .docx, .doc, .pdf files accepted
                  </Typography>
                </Stack>
              </UploadFileWrapper>
              {selectedFiles.length > 0 && (
                <FileInfoWrapper>
                  {selectedFiles.map((file, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <FileInfo key={index}>
                      <IconButton color="info" sx={{ '& .MuiSvgIcon-root': { fontSize: '2rem' } }} aria-label="file">
                        <InsertDriveFileIcon />
                      </IconButton>
                      <Box sx={{ flexGrow: 1 }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                          <Typography variant="subtitle2" textAlign="left">
                            {file.name}
                          </Typography>
                        </Stack>
                        <LinearProgress variant="determinate" color="info" value={uploadProgress[index]} />
                        <Typography
                          variant="subtitle2"
                          mt="5px"
                          color="text.grey"
                          textAlign="left"
                          sx={{ fontSize: '12px' }}
                        >
                          {uploadProgress[index]}% done
                        </Typography>
                      </Box>
                    </FileInfo>
                  ))}
                </FileInfoWrapper>
              )}
              {completed ? (
                <DocumentTable />
              ) : (
                <Box padding="2rem 0">
                  <ImgWrapper src={uploadBot} alt="uploadBot" />
                  <Typography variant="subtitle1">Data not uploaded</Typography>
                  <Typography variant="caption" color="text.primary">
                    Upload document and Train AI in order to see the trained data{' '}
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            <TrainingAI />
          )}
        </Box>
      </ContentWrapper>
    </TrainAIWrapper>
  );
};

export default TrainAI;
