import styled from '@emotion/styled';
import { Avatar, Box, Chip, Stack, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const ExpertCardWrapper = styled.div`
  padding: '20px';
`;
const ExpertCard = ({ expert }) => (
  <ExpertCardWrapper>
    <Stack direction="row" gap={4} justifyContent="space-between" border="1px solid #1C1D23" borderRadius={2} p={2}>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        <Avatar
          sx={{
            height: 120,
            width: 120,
            objectFit: 'cover',
            mr: 1,
          }}
        />
        <Stack direction="column" alignItems="start" gap={1}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography>{expert.username}</Typography>
            <Chip color="success" label={expert.status} variant="outlined" size="small" />
          </Box>
          <Typography>{expert.designaion}</Typography>
          <Box sx={{ display: 'flex', gap: 2, color: '#888888' }}>
            <Typography fontSize="15px">{expert.email}</Typography>
            <Typography fontSize="15px">{expert.phone}</Typography>
            <Typography fontSize="15px">India</Typography>
          </Box>
          <Typography textAlign="start" color="#BBBBBB" fontSize="15px">
            {expert.bio}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  </ExpertCardWrapper>
);

ExpertCard.propTypes = {
  expert: PropTypes.object.isRequired,
};

export default ExpertCard;
