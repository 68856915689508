import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import chatBotLogo from '../../assets/chatBotLogo.png';

const BotResponse = ({ response }) => (
  <Box
    sx={{
      backgroundColor: 'text.grey400',
      borderRadius: '8px',
      padding: '1rem',
      width: '95%',
      my: '1.5rem ',
      display: 'flex',
      alignItems: 'flex-start',
    }}
  >
    <img src={chatBotLogo} alt="" style={{ marginRight: '1rem' }} />

    {response && (
      <Typography
        mt={1}
        textAlign="left"
        sx={{
          whiteSpace: 'pre-wrap',
        }}
      >
        {response}
      </Typography>
    )}
  </Box>
);

BotResponse.propTypes = {
  response: PropTypes.string.isRequired,
};

export default BotResponse;
