import styled from '@emotion/styled';
import DataTable from 'react-data-table-component';

export const TrainAIWrapper = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

export const ContentWrapper = styled.div`
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
`;

export const TextWrapper = styled.div`
  padding: 1rem 2rem;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const UploadFileWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.blackShade};
  margin: 0.5rem 1.5rem;
  padding: 36px 100px;
  border: 1px dashed ${({ theme }) => theme.palette.primary.main};
  border-radius: 16px;

  @media (max-width: 600px) {
    padding: 7px 21px;
  }
`;

export const ImgWrapper = styled.img`
  height: 15rem;

  @media (max-width: 475px) {
    height: 9rem;
  }
`;

export const FileInfoWrapper = styled.div`
  margin: 0.5rem 1.5rem;
  padding: 1rem;
  border: 1px solid #1c1d23;
  border-radius: 16px;
  margin-top: 1rem;

  @media (max-width: 475px) {
    padding: 0.5rem;
  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
`;

export const TableWrapper = styled.div`
  padding: 1rem 2rem;
`;

export const CustomDataTable = styled(DataTable)`
  .rdt_Table {
    border-radius: 12px;
    background-color: ${({ theme }) => theme.palette.secondary.blackShade};
    border: 1px solid ${({ theme }) => theme.palette.text.grey300};

    .sc-hmdomO.cTsUPQ {
      border-radius: 12px;
      background-color: ${({ theme }) => theme.palette.secondary.blackShade};
      color: white !important;
    }
  }

  .rdt_TableCell input[type='checkbox'],
  .rdt_TableCell input[type='checkbox']:checked {
    background-color: ${({ theme }) => theme.palette.primary.main} !important;
    border-color: ${({ theme }) => theme.palette.primary.main} !important;
  }

  .rdt_TableHeadRow {
    background-color: ${({ theme }) => theme.palette.secondary.blackShade};
    color: white;
    border: 1px solid ${({ theme }) => theme.palette.text.grey300};
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .rdt_TableBody,
  .rdt_TableRow {
    color: white;
    border: 1px solid ${({ theme }) => theme.palette.text.grey300};
    background-color: ${({ theme }) => theme.palette.secondary.light};
  }

  .rdt_TableCol {
    letter-spacing: 2px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.secondary.blackShade};
  padding: 0.5rem 1rem;
  border-radius: 10px;
  width: 350px;

  input {
    background-color: ${({ theme }) => theme.palette.secondary.blackShade};
    border: none;
    outline: none;
    margin-right: auto;
    color: white;

    ::placeholder {
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }
`;
